import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import 'jodit';
import 'jodit/build/jodit.min.css';

import { Jodit } from 'jodit';

import 'jquery-ui-dist/jquery-ui'; 
//import { offset } from "jodit/types/core/helpers";


import Rails from "@rails/ujs";
Rails.start();

import * as ActiveStorage from "@rails/activestorage";
ActiveStorage.start();

import Turbolinks from "turbolinks";
Turbolinks.start();

import M from 'materialize-css';
import 'materialize-css/dist/css/materialize.min.css'; 

import select2 from "select2";

document.addEventListener('DOMContentLoaded', function() {
    M.AutoInit();
});

import "@nathanvda/cocoon";  

function createJodit(editorId, hiddenFieldId) {
    const toolbarOptions = [
      "bold",
      "italic",
      "underline",
      "|",
      "align",
      "|",
      "outdent",
      "indent",
      "|",
      "brush",
      "marker",
      "|",
      "ul",
      "ol",
      "|",
      "paragraph",
      "|",
      "font",
      "|",
      "table",
    ];
  
    const editor = Jodit.make(editorId, {
      toolbar: toolbarOptions,
    });
  
    const hiddenField = document.getElementById(hiddenFieldId);
  
    editor.events.on('change', function () {
      if(editorId == '#service-table-editor'){
        const tables = editor.editor.querySelectorAll('table');
  
        tables.forEach((table) => {
          // Si un élément <thead> n'est pas présent, créez-en un
          if (!table.querySelector('thead')) {
            const thead = document.createElement('thead');
            const firstRow = table.querySelector('tr');
  
            if (firstRow) {
              // Déplacez la première ligne du tableau vers le nouvel élément <thead>
              firstRow.parentNode.removeChild(firstRow);
              thead.appendChild(firstRow);
              table.insertBefore(thead, table.firstChild);
            }
          }
        });
      }
      
      hiddenField.value = editor.value;
    });
  
    if (hiddenField.value) {
      editor.value = hiddenField.value;
    }
  
    return editor;
  }

function updateTagIdsField(chipsInstance) { 
    var tagIds = chipsInstance.chipsData.map(function(chipData) {
      return chipData.id;
    });
    document.getElementById('tag-ids').value = tagIds.join(',');
}

function initTableHeight() {
    const tableau = $(".table_box");
    const espaceEnHaut = tableau.offset().top;
    const hauteurEcran = $(window).height();
    const hauteurMax = hauteurEcran - espaceEnHaut - 10;
  
    tableau.css('max-height', hauteurMax + 'px');
    tableau.css('overflow-y', 'auto');
  }
  
  function hideBorderPrevLine() {
    $('.invoice_association').each(function(){
      if($(this).is(":hidden")){
        $(this).prev().css('border-bottom', '1px solid rgba(0,0,0,0.12)');
      } else {
        $(this).prev().css('border-bottom', 'none');
      }
    });
  }

function calculateAndUpdateStorage() { 
    let totalSurface = 0;
    let totalVolume = 0;

    $('.location').each(function() {
        let height = parseFloat($(this).find("input.height").val());
        let length = parseFloat($(this).find("input.length").val());
        let width = parseFloat($(this).find("input.width").val());

        // Pour la surface, on prend la longueur * largeur
        totalSurface += length * width;

        // Pour le volume, on prend la longueur * largeur * hauteur
        totalVolume += length * width * height;
    });

    // Conversion des centimètres carrés en mètres carrés et des centimètres cubes en mètres cubes
    let totalSurfaceInM2 = totalSurface / 10000;
    let totalVolumeInM3 = totalVolume / 1000000;

    // Mettre à jour les éléments HTML avec les valeurs calculées
    $('#total-surface').text(totalSurfaceInM2.toFixed(2));
    $('#total-volume').text(totalVolumeInM3.toFixed(2));
}


function hexToRgb(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? 'rgb(' +
        parseInt(result[1], 16) + ',' +
        parseInt(result[2], 16) + ',' +
        parseInt(result[3], 16) + ')' : null;
}

function calculateWarehouseDivSize(){
    // Ajuster la largeur de la div warehouse-layout en fonction du nombre d'allées
    let aisleWidth = $(".aisle").outerWidth(true); // obtient la largeur de l'allée, y compris la marge
    let numberOfAisles = $(".aisle").length; // obtient le nombre total d'allées
    $("#warehouse-layout").css("width", aisleWidth * (numberOfAisles+1) + "px"); // ajuste la largeur de la div warehouse-layout
}

function getLetter(index) {
    let baseChar = ("A").charCodeAt(0);
    let letters  = "";

    do {
        index -= 1;
        letters = String.fromCharCode(baseChar + (index % 26)) + letters;
        index = (index / 26) >> 0; // quick way to get integer part
    } while(index > 0);

    return letters;
}

function getLocationCapacity(location) {
    // utiliser l'attribut data-capacity pour obtenir la capacité de l'emplacement
    return $(location).data('capacity');
}

function getUnitId(location) {
    // utiliser l'attribut data-unit-id pour obtenir l'ID de l'unité de l'emplacement
    return $(location).data('unit-id');
}

function setEntryAvailableLocations(productId, product_select, at_init){

    if (!productId || productId === "0" || productId === undefined) {
        product_select.closest(".added_product").find('.warehouse_layout').empty();
        return;
    }

    // Affiche le loader
    var loaderHtml = '<div class="product_loader"><div class="progress"><div class="indeterminate"></div></div></div>';
    product_select.closest(".added_product").find('.warehouse_layout').html(loaderHtml);

    $.ajax({
        url: '/products/' + productId + '/product_unit_associations',
        method: 'GET',
        dataType: 'json',
        success: function(data) {
            console.log(data)
            if(at_init == false){
                $(product_select).closest(".added_product").find('.quantities_by_package').empty();
                data.forEach(function(unit) { 
                    var html = `
                        <div class="col s3">
                            <label>${unit.name} quantity</label>
                            <input type="text" name="quantity_by_type" data-unit-id="${unit.id}" data-unit-name="${unit.name}">
                        </div>
                    `;
                    $(product_select).closest(".added_product").find('.quantities_by_package').append(html);
                });
            }
        }
    });

    $.ajax({  
        url: '/products/' + productId + '/available_locations',
        method: 'GET',
        dataType: 'html',
        success: function(data) {
            console.log(data)
            $(product_select).closest(".added_product").find('.warehouse_layout').html(data);
            $(product_select).closest(".added_product").find(".storage_type").show();
            resize_warehouse();
            var div_added_product = $(product_select).closest(".added_product");
            removeWarehouseFromTabCells(div_added_product);
            addWarehouseToTabCells(div_added_product);
            product_select.closest(".added_product").find('.product_loader').remove();
        }
    });
}

function resetInitialStock(location){
    // Obtenir toutes les données de l'emplacement
    var data = $(location).data();
    var div_added_product = $(location).closest(".added_product");
    // Trouver l'index de l'entrepôt correspondant
    var index = $(".added_product").index(div_added_product);
    var warehouse = tab_cells_by_warehouse[index];
    var product_id = $(div_added_product).find('.product_entry_item_product_id').val();
    var cellId = data["cellId"];
    // Trouver les dimensions du produit pour cette unité de quantité
    var dimensions_for_product = warehouse["product_dimensions"]
    // Trouver la cellule concernée
    var cell = warehouse.cells.find(function(c) {
        return c["cell_id"] == cellId;
    });
    if(cell){
        $(cell.quantities).each(
            function(i, quantity){
                setQuantityToCellInWarehouse(div_added_product, cellId, product_id, quantity.unit_quantity_id, 0);
            }
        );
    }

    $(location).closest("td").find("input[name='selected_locations_quantities[]']").remove();
    $(location).closest("td").find("input[name='selected_locations_unit_quantity_ids[]']").remove();
    $(location).closest("td").find("#quantity_for_location_"+$(location).val()).val("");
    $(location).closest("td").find(".created_stock").remove();

    // Effacer le texte de la quantité et de l'unité stockées
    $(location).parent().find("span.quantity_and_unit").text("");
}

function refreshExpeditionLimiteDate(form){
    var customer_id = $("#product_entry_customer_id").val();
    var product_id = $(form).find(".product_entry_item_product_id").val();
    var peremption_date = $(form).find("input[name*=best_before_date]").val();

    $.ajax({
        url: '/products/'+product_id+'/get_date_limite_expedition_for_product_and_customer',
        method: 'GET',
        dataType: 'json',
        data: { customer_id : customer_id, peremption_date : peremption_date },
        success: function(data) {
            if(data.success == true)
                $(form).find("input[name*=expiry_date]").val(data.expedition_limite_date.substr(0,10));
        }
    });
}

// Convertit une quantité d'une unité donnée en volume
function unitToVolume(unitId, quantity, dimensions) {
    // Vérifie que les dimensions existent pour l'unité donnée
    if (!dimensions || !dimensions.height || !dimensions.width || !dimensions.length) {
        console.error(`Dimensions non fournies pour l'unité ${unitId}`);
        return null;
    }

    // Convertit la quantité en volume
    const volume = quantity * dimensions.height * dimensions.width * dimensions.length;
    return volume;
}

// Convertit un volume en quantité pour une unité donnée
function volumeToUnit(unitId, volume, dimensions) {
    // Vérifie que les dimensions existent pour l'unité donnée
    if (!dimensions || !dimensions.height || !dimensions.width || !dimensions.length) {
        console.error(`Dimensions non fournies pour l'unité ${unitId}`);
        return null;
    }

    // Convertit le volume en quantité
    const quantity = volume / (dimensions.height * dimensions.width * dimensions.length);
    return quantity;
}

var tab_cells_by_warehouse = [];
function addWarehouseToTabCells(div_added_product){
    var index = $(".added_product").index(div_added_product);
    var data_warehouse = { index: index, product_id : $(div_added_product).find(".product_entry_item_product_id").val(), product_dimensions : $(div_added_product).find(".product_entry_item_product_id option:selected").data("dimensions"), cells : [] };
    var cells = []
    var cell_to_refresh = []
    $(div_added_product).find(".td-location").each(
        function(i, td_location){
            var data_cell = $(td_location).find("input[name='selected_locations[]']").data();
            var created_stocks = $(td_location).find(".created_stock");
            var quantities = []
            $(created_stocks).each(
                function(i, stock){
                    quantities.push({
                        unit_quantity_id: $(stock).data("stock-unit-quantity-id"),
                        quantity: $(stock).data("stock-quantity")
                    });
                }
            )
            var cell = { cell_id : data_cell["cellId"], volume_available : data_cell["volumeAvailable"], quantities : quantities }
            //Si c'est le premier entrepot pas besoin de vérifier le volume
            if(tab_cells_by_warehouse.length > 0){
                // Sinon on va chercher le volume du premier warehouse, il fera référence vu qu'ils sont synchro
                var cell_first_warehouse = tab_cells_by_warehouse[0]["cells"].find(function(c) { 
                    return c["cell_id"] == cell.cell_id; 
                });
                if(cell_first_warehouse != undefined&&cell["volume_available"]!=cell_first_warehouse["volume_available"]){
                    //on les synchronise
                    cell["volume_available"]=cell_first_warehouse["volume_available"];
                    cell_to_refresh.push(cell);
                    
                }
            }
            //on ajoute la cell qu'on vient de traiter aux cells du warehouse
            cells.push(cell);
        }
    );
    //une fois traités toutes les cells on les ajoute au warehouse
    data_warehouse["cells"] = cells;
    //on ajoute le warehouse aux autres
    tab_cells_by_warehouse.push(data_warehouse);

    $(cell_to_refresh).each(
        function(i, cell){
            refreshViewCell(div_added_product, cell["cell_id"]);
        }
    );
}

function removeWarehouseFromTabCells(div_added_product) {
    // Trouver l'index de l'entrepôt à retirer
    var indexToRemove = $(".added_product").index(div_added_product);

    // Récupérer l'entrepôt et ses dimensions
    var warehouseToRemove = tab_cells_by_warehouse[indexToRemove];
    var productDimensions = warehouseToRemove["product_dimensions"];

    // Parcourir chaque cellule de l'entrepôt à retirer
    warehouseToRemove.cells.forEach(function(cell) {
        if (cell["quantities"].length > 0) {
            // Calculer le volume occupé par les produits dans cette cellule
            cell["quantities"].forEach(function(quantity) {
                var dimension = productDimensions.find(function(d) {
                    return d["unit_quantity_id"] == quantity.unit_quantity_id;
                });
                var volumeToRemove = quantity.quantity * dimension.volume;

                // Mettre à jour le volume disponible dans les cellules des autres entrepôts
                tab_cells_by_warehouse.forEach(function(otherWarehouse, k) {
                    if (k !== indexToRemove) {
                        var otherCell = otherWarehouse.cells.find(function(c) {
                            return c["cell_id"] === cell["cell_id"];
                        });
                        if (otherCell) {
                            otherCell["volume_available"] += volumeToRemove;
                            refreshViewCell($(".added_product").eq(k), otherCell["cell_id"]);
                        }
                    }
                });
            });
        }
    });

    // Retirer l'entrepôt du tableau
    tab_cells_by_warehouse.splice(indexToRemove, 1);

    // Mettre à jour les index des entrepôts restants
    tab_cells_by_warehouse.forEach(function(warehouse, i) {
        warehouse.index = i;
    });
}


function refreshViewCell(div_added_product, cellId){
    // Trouver l'index de l'entrepôt correspondant
    var index = $(".added_product").index(div_added_product);
    var warehouse = tab_cells_by_warehouse[index];

    // Trouver les dimensions du produit pour cette unité de quantité
    var dimensions_for_product = warehouse["product_dimensions"]
    // Trouver la cellule concernée
    var cell = warehouse.cells.find(function(c) {
        return c["cell_id"] == cellId;
    });
    var volume_available_cell = cell["volume_available"];
    $(dimensions_for_product).each(
        function(i, dimension){
            var volume_per_unit = dimension.length * dimension.width * dimension.height;
            var quantity = Math.floor(volume_available_cell/volume_per_unit);
            // et la tu mets a jour le html qui va bien
            $(div_added_product).find(".td-location[data-location-id='"+cellId+"']").find(".span-capacity-unit-" + dimension.unit_quantity_id + " .span-capacity-quantity").text(quantity);
        }
    )
}

function setQuantityToCellInWarehouse(div_added_product, cellId, product_id, unitId, quantity) {
    // Trouver l'index de l'entrepôt correspondant
    var index = $(".added_product").index(div_added_product);
    var warehouse = tab_cells_by_warehouse[index];

    // Trouver les dimensions du produit pour cette unité de quantité
    var dimension_for_unit = warehouse["product_dimensions"].find(function(d) {
        return d["unit_quantity_id"] == unitId;
    });

    // Calculer le volume occupé par cette quantité du produit
    var new_volume = quantity * dimension_for_unit.volume;

    // Trouver la cellule concernée
    var cell = warehouse.cells.find(function(c) {
        return c["cell_id"] == cellId;
    });

    if (cell) {
        // Vérifier si cette unité de quantité existe déjà dans la cellule
        var existing_quantity_index = cell["quantities"].findIndex(function(q) {
            return q["unit_quantity_id"] == unitId;
        });

        if (existing_quantity_index !== -1) {
            // Calculer le volume existant
            var existing_quantity = cell["quantities"][existing_quantity_index];
            var existing_volume = existing_quantity["quantity"] * dimension_for_unit.volume;

            // Si la quantité est nulle, supprimer cet élément du tableau
            if (quantity === 0) {
                cell["quantities"].splice(existing_quantity_index, 1);
            } else {
                // Sinon, remplacer la quantité existante
                existing_quantity["quantity"] = quantity;
            }

            // Mettre à jour le volume disponible
            var volume_difference = existing_volume - new_volume;

            // Appliquer la différence de volume à toutes les cellules synchronisées
            $.each(tab_cells_by_warehouse, function(i, other_warehouse) {
                var other_cell = other_warehouse.cells.find(function(c) {
                    return c["cell_id"] == cellId;
                });

                if (other_cell) {
                    other_cell["volume_available"] = parseFloat(other_cell["volume_available"]) + volume_difference;
                    refreshViewCell($($(".added_product")[i]), cellId);
                }
            });
        } else if (quantity !== 0) {
            // Si la quantité n'est pas nulle, ajouter cette nouvelle quantité
            cell["quantities"].push({
                unit_quantity_id: unitId,
                quantity: quantity
            });

            // Mettre à jour le volume disponible pour cette cellule et les cellules synchronisées
            cell["volume_available"] -= new_volume;
            refreshViewCell(div_added_product, cellId);

            $.each(tab_cells_by_warehouse, function(i, other_warehouse) {
                if (i !== index) {
                    var other_cell = other_warehouse.cells.find(function(c) {
                        return c["cell_id"] == cellId;
                    });

                    if (other_cell) {
                        other_cell["volume_available"] -= new_volume;
                        refreshViewCell($($(".added_product")[i]), cellId);
                    }
                }
            });
        }
    }
}

function selectLocationsHorizontally(quantities_by_package, clicked_element) {
    // obtenir tous les emplacements disponibles
    var availableLocations = $(clicked_element).closest(".table_warehouse").find('input[name="selected_locations[]"]:not(:disabled)');
    var product_id = $(clicked_element).closest(".added_product").find('.product_entry_item_product_id').val();
    var canStoreAllUnits = true;
    var totalStoredByUnit = {};

    // trouver l'index de l'emplacement coché
    var checkedIndex = availableLocations.index(clicked_element);
    var div_added_product = $(clicked_element).closest(".added_product");

    // décocher toutes les cases cochées
    availableLocations.prop('checked', false);
    $(availableLocations).each(
        function(i, location){ 
            resetInitialStock(location);
        }
    )

    //$(availableLocations).trigger("click");

    // Parcourir toutes les quantités pour chaque type d'unité
    for (var packageIndex = 0; packageIndex < quantities_by_package.length; packageIndex++) {
        var package_type = quantities_by_package[packageIndex];
        var unitId = package_type.unit_id;
        var quantity = package_type.quantities;
        var storedForThisUnit = 0;
        if(isNaN(quantity)){
            quantity = 0;
        }

        // Copie des états initiaux
        var initialStates = [];
        availableLocations.each(function (index, element) {
            initialStates.push({
                checked: element.checked,
                data: $.extend(true, {}, $(element).data())
            });
        });
    
        for (var i = checkedIndex; i < availableLocations.length && quantity > 0; i++) {
            var location = availableLocations[i];
            var cellId = $(location).data('cell-id');
        
            // Trouve l'entrepôt et la cellule correspondante
            var warehouseIndex = $(".added_product").index(div_added_product);
            var warehouse = tab_cells_by_warehouse[warehouseIndex];
            var cell = warehouse.cells.find(function(c) {
                return c["cell_id"] == cellId;
            });
        
            var volumeAvailable = cell["volume_available"];
            var volumePerUnit = warehouse["product_dimensions"].find(function(d) {
                return d["unit_quantity_id"] == unitId;
            }).volume;
        
            var maxQuantity = Math.floor(volumeAvailable / volumePerUnit);
        
            if (maxQuantity >= quantity) {
                storedForThisUnit += quantity;
                location.checked = true;
                $(location).parent().find("span.quantity_and_unit").text(unitId + " : " + quantity);
        
                var remainingVolume = volumeAvailable - (quantity * volumePerUnit);
        
                setQuantityToCellInWarehouse(div_added_product, cellId, product_id, unitId, quantity);
        
                var location_id = $(location).val();
                $(location).parent().find("#quantity_for_location_" + location_id).remove();
                $(location).before("<input name='selected_locations_quantities[]' data-location-id='" + location_id + "' type='hidden' value='" + quantity + "' />");
        
                $(location).parent().find("#unit_quantity_id_for_location_" + location_id).remove();
                $(location).before("<input name='selected_locations_unit_quantity_ids[]' data-location-id='" + location_id + "' type='hidden' value='" + unitId + "' />");
        
                quantity = 0;
            } else if (maxQuantity > 0) {
                storedForThisUnit += maxQuantity;
                $(location).parent().find("span.quantity_and_unit").text(unitId + " : " + maxQuantity);
        
                setQuantityToCellInWarehouse(div_added_product, cellId, product_id, unitId, maxQuantity);
        
                var location_id = $(location).val();
                $(location).parent().find("#quantity_for_location_" + location_id).remove();
                $(location).before("<input name='selected_locations_quantities[]' data-location-id='" + location_id + "' type='hidden' value='" + maxQuantity + "' />");
        
                $(location).parent().find("#unit_quantity_id_for_location_" + location_id).remove();
                $(location).before("<input name='selected_locations_unit_quantity_ids[]' data-location-id='" + location_id + "' type='hidden' value='" + unitId + "' />");
        
                quantity -= maxQuantity;
                location.checked = true;
            }
        
            if (quantity === 0) {
                checkedIndex = i + 1;
                break;
            }
        }        

        totalStoredByUnit[unitId] = storedForThisUnit;

        if (quantity !== 0) {
            canStoreAllUnits = false;
        }
    

    }

    // Mettre à jour le total et la couleur dans la div
    var totalFilledDiv = $(clicked_element).closest(".added_product").find(".total_filled");
    var filledText = "Total rempli par unité : ";
    var allFilled = true;

    for (var unit in totalStoredByUnit) {
        if(quantities_by_package.find(p => p.unit_id === unit).quantities > 0){
            var unitName = $("input[data-unit-id='" + unit + "']").data('unit-name');
            filledText += unitName + " : " + totalStoredByUnit[unit] + " / ";
            if (totalStoredByUnit[unit] < quantities_by_package.find(p => p.unit_id === unit).quantities) {
                allFilled = false;
            }
        }
    }
    
    totalFilledDiv.text(filledText);

    if (allFilled) {
        totalFilledDiv.removeClass("red_c").addClass("green_c");
    } else {
        totalFilledDiv.removeClass("green_c").addClass("red_c");
    }
}

function selectLocationsVertically(quantities_by_package, clicked_element) {
    var table_warehouse = $(clicked_element).closest(".table_warehouse");
    var aisles = $(table_warehouse).find('.td_aisle');

    // trouver l'allée de l'emplacement coché
    var checkedAisle = $(clicked_element).closest('.td_aisle');

    // obtenir l'index de l'allée coché
    var checkedAisleIndex = aisles.index(checkedAisle);

    // trouver le niveau de l'emplacement coché
    var checkedLevel = $(clicked_element).closest('.level_col');

    // obtenir l'index du niveau coché
    var checkedLevelIndex = checkedAisle.find('.level_col').index(checkedLevel);

    // obtenir l'index de l'emplacement coché dans son niveau
    var checkedLocationIndex = checkedLevel.find('input[name="selected_locations[]"]:not(:disabled)').index(clicked_element);

    // décocher toutes les cases cochées
    var availableLocations = $(table_warehouse).find('input[name="selected_locations[]"]:not(:disabled)');
    availableLocations.prop('checked', false);
    $(availableLocations).each(
        function(i, location){
            resetInitialStock(location);
        }
    )

    // définir le compteur de produits
    var count = 0;

    var totalStoredByUnit = {};

    // définir le nombre de niveaux maximum dans une allée
    var maxLocationCount = 0;
    aisles.each(function(i, aisle) {
        var locationCount = $(this).find('input[name="selected_locations[]"]:not(:disabled)').length;
        if (locationCount > maxLocationCount) {
            maxLocationCount = locationCount;
        }
    });

    // Parcourir toutes les quantités pour chaque type d'unité
    for (var packageIndex = 0; packageIndex < quantities_by_package.length; packageIndex++) {
        var storedForThisUnit = 0;
        var package_type = quantities_by_package[packageIndex];
        var unitId = package_type.unit_id;
        var quantity = package_type.quantities;
        if(isNaN(quantity)){
            quantity = 0;
        }

        // parcourir chaque allée à partir de l'allée coché
        var lastAisle = checkedAisleIndex;
        var lastLevel = checkedLevelIndex;
        var lastLocation = checkedLocationIndex;
        for (var a = checkedAisleIndex; a < aisles.length && quantity > 0; a++) {
            lastAisle = a;
            var aisle = aisles.eq(a);
            var startLocationIndex = checkedLocationIndex; // Définir l'index de départ de l'emplacement

            // pour chaque emplacement à partir de l'emplacement coché
            for (var i = startLocationIndex; i < maxLocationCount && quantity > 0; i++) {
                lastLocation = i;
                if(i > startLocationIndex){
                    checkedLevelIndex = 0;
                }

                // parcourir chaque niveau à partir du niveau coché
                for (var j = checkedLevelIndex; j < aisle.find('.level_col').length && quantity > 0; j++) {
                    lastLevel = j;
                    var level = aisle.find('.level_col').eq(j);

                    // obtenir l'emplacement courant pour ce niveau
                    var location = level.find('input[name="selected_locations[]"]:not(:disabled)').eq(i);

                    if (location.length > 0) {
                        var cellId = $(location).data('cell-id');
        
                        // Trouve l'entrepôt et la cellule correspondante
                        var div_added_product = $(location).closest(".added_product");
                        var product_id = $(div_added_product).find(".product_entry_item_product_id").val();
                        var warehouseIndex = $(".added_product").index(div_added_product);
                        var warehouse = tab_cells_by_warehouse[warehouseIndex];
                        var cell = warehouse.cells.find(function(c) {
                            return c["cell_id"] == cellId;
                        });
                    
                        var volumeAvailable = cell["volume_available"];
                        var volumePerUnit = warehouse["product_dimensions"].find(function(d) {
                            return d["unit_quantity_id"] == unitId;
                        }).volume;
                    
                        var maxQuantity = Math.floor(volumeAvailable / volumePerUnit);
                    
                        if (maxQuantity >= quantity) {
                            storedForThisUnit += quantity;
                            $(location)[0].checked = true;
                            $(location).prop('checked', true);
                            $(location).parent().find("span.quantity_and_unit").text(unitId + " : " + quantity);
                    
                            var remainingVolume = volumeAvailable - (quantity * volumePerUnit);
                    
                            setQuantityToCellInWarehouse(div_added_product, cellId, product_id, unitId, quantity);
                    
                            var location_id = $(location).val();
                            $(location).parent().find("#quantity_for_location_" + location_id).remove();
                            $(location).before("<input name='selected_locations_quantities[]' data-location-id='" + location_id + "' type='hidden' value='" + quantity + "' />");
                    
                            $(location).parent().find("#unit_quantity_id_for_location_" + location_id).remove();
                            $(location).before("<input name='selected_locations_unit_quantity_ids[]' data-location-id='" + location_id + "' type='hidden' value='" + unitId + "' />");
                    
                            quantity = 0;
                        } else if (maxQuantity > 0) {
                            storedForThisUnit += maxQuantity;
                            $(location).parent().find("span.quantity_and_unit").text(unitId + " : " + maxQuantity);
                    
                            setQuantityToCellInWarehouse(div_added_product, cellId, product_id, unitId, maxQuantity);
                    
                            var location_id = $(location).val();
                            $(location).parent().find("#quantity_for_location_" + location_id).remove();
                            $(location).before("<input name='selected_locations_quantities[]' data-location-id='" + location_id + "' type='hidden' value='" + maxQuantity + "' />");
                    
                            $(location).parent().find("#unit_quantity_id_for_location_" + location_id).remove();
                            $(location).before("<input name='selected_locations_unit_quantity_ids[]' data-location-id='" + location_id + "' type='hidden' value='" + unitId + "' />");
                    
                            quantity -= maxQuantity;
                            $(location)[0].checked = true;
                            $(location).prop('checked', true);
                        }
                    
                        if (quantity === 0) {
                            checkedIndex = i + 1;
                            break;
                        }
                    }
                }

                startLocationIndex = 0; // Réinitialiser l'index de départ de l'emplacement pour le prochain niveau
            }

            checkedLocationIndex = 0; //on repart au début de l'allée suivante
        }

        totalStoredByUnit[unitId] = storedForThisUnit;
        checkedAisleIndex = lastAisle;
        checkedLocationIndex = lastLocation;
        checkedLevelIndex = lastLevel;
    }

    // Mettre à jour le total et la couleur dans la div
    var totalFilledDiv = $(".total_filled");
    var filledText = "Total rempli par unité : ";
    var allFilled = true;

    for (var unit in totalStoredByUnit) {
        if(quantities_by_package.find(p => p.unit_id === unit).quantities > 0){
            var unitName = $("input[data-unit-id='" + unit + "']").data('unit-name');
            filledText += unitName + " : " + totalStoredByUnit[unit] + " / ";
            if (totalStoredByUnit[unit] < quantities_by_package.find(p => p.unit_id === unit).quantities) {
                allFilled = false;
            }
        }
    }

    totalFilledDiv.text(filledText);

    if (allFilled) {
        totalFilledDiv.removeClass("red_c").addClass("green_c");
    } else {
        totalFilledDiv.removeClass("green_c").addClass("red_c");
    }
}

function resize_warehouse(){
    // Récupère le nombre maximum de <tr> dans un <td> .level_col
    const tdLevels = document.querySelectorAll('.level_col');
    let maxRows = 0;
    tdLevels.forEach(td => {
        const rowsCount = td.querySelectorAll('tr').length;
        if(rowsCount > maxRows) {
            maxRows = rowsCount;
        }
    });

    // Pour chaque position de <tr>, détermine la hauteur maximale
    for (let i = 0; i < maxRows; i++) { 
        let maxRowHeight = 0;
        
        tdLevels.forEach(td => {
            const row = td.querySelectorAll('tr')[i]; 
            if(row && row.offsetHeight > maxRowHeight) {
                maxRowHeight = row.offsetHeight;
            }
        });
        
        // Applique la hauteur maximale à tous les <tr> à cette position
        tdLevels.forEach(td => {
            const row = td.querySelectorAll('tr')[i];
            if(row) {
                row.style.height = maxRowHeight + "px";
            }
        });
    }
}

function updateTotalDifferenceByUnit(productId, unitId, customerId) {
    let totalDifference = 0;
    const allDifferenceTdsForProduct = document.querySelectorAll(`.stock_difference[data-product-id="${productId}"][data-unit-id="${unitId}"]`);
    allDifferenceTdsForProduct.forEach(td => {
        if(!isNaN(parseFloat(td.textContent))){
            totalDifference += parseFloat(td.textContent);
        }
    });

    // Mettre à jour l'écart dans le tableau des totaux par client
    let totalDifferenceByClient = 0;
    const allDifferenceTdsForClient = document.querySelectorAll(`.stock_difference[data-product-id="${productId}"][data-unit-id="${unitId}"][data-customer-id="${customerId}"]`);
    allDifferenceTdsForClient.forEach(td => {
        if (!isNaN(parseFloat(td.textContent))) {
            totalDifferenceByClient += parseFloat(td.textContent);
        }
    });

    const totalDifferenceTdByClient = document.querySelector(`#total-difference-client-${customerId}-product-${productId}-unit-${unitId}`);
    totalDifferenceTdByClient.textContent = totalDifferenceByClient;
}

function adjustHiddenFieldForLocation(stockId, locationId, quantityDelta, transfer_item_index) {
    var hiddenField = $('.hidden-field-for-stock-' + stockId + '-location-' + locationId);

    // Si le champ caché existe déjà
    if (hiddenField.length) {
        var updatedQuantity = parseInt(hiddenField.val(), 10) + quantityDelta;

        // Si la quantité mise à jour est 0 ou inférieure, supprimez le champ caché
        if (updatedQuantity <= 0) {
            hiddenField.remove();
        } else {
            hiddenField.val(updatedQuantity);
        }
    } else if (quantityDelta > 0) { // Ne créez un nouveau champ caché que si la quantité est positive
        $('.form-product-transfer').append(`<input type="hidden" class="hidden-field-for-stock-${stockId}-location-${locationId}" name="transfer[${transfer_item_index}][${stockId}][${locationId}]" value="${quantityDelta}">`);
    }
}

function updateEntryTitle(){
    var date = $("#product_entry_entry_date").val();
    date = date.split("-")[2] + "/" + date.split("-")[1] + "/" + date.split("-")[0]
    var customer_id = $("#product_entry_customer_id").val();
    var customer = " "
    if(customer_id > 0){
        customer = " " + $("#product_entry_customer_id").find("option:selected").text() + " ";
    }
    var current_title = $("#product_entry_title").val();
    var new_title = "";

    //normalement c'est la situation de départ donc on cale le nom du client entre les deux
    new_title = current_title.split(" ")[0] + customer + date;

    $("#product_entry_title").val(new_title);
}

function updateExitTitle(){
    var date = $("#product_exit_exit_date").val();
    date = date.split("-")[2] + "/" + date.split("-")[1] + "/" + date.split("-")[0]
    var customer_id = $("#product_exit_customer_id").val();
    var customer = " "
    if(customer_id > 0){
        customer = " " + $("#product_exit_customer_id").find("option:selected").text() + " ";
    }
    var current_title = $("#product_exit_title").val();
    var new_title = "";

    //normalement c'est la situation de départ donc on cale le nom du client entre les deux
    new_title = current_title.split(" ")[0] + customer + date;

    $("#product_exit_title").val(new_title);
}

function updateTransferTitle(){
    var date = $("#product_transfer_transfer_date").val();
    date = date.split("-")[2] + "/" + date.split("-")[1] + "/" + date.split("-")[0]
    var customer_id = $("#product_transfer_customer_id").val();
    var customer = " "
    if(customer_id > 0){
        customer = " " + $("#product_transfer_customer_id").find("option:selected").text() + " ";
    }
    var current_title = $("#product_transfer_title").val();
    var new_title = "";

    //normalement c'est la situation de départ donc on cale le nom du client entre les deux
    new_title = current_title.split(" ")[0] + customer + date;

    $("#product_transfer_title").val(new_title);
}

function selectStockFifo(quantities, traversal_type, clicked_element) {
    // obtenir tous les stocks disponibles
    var availableStocks = $(clicked_element).closest(".table_warehouse").find('input[name="selected_stocks[]"]:not(:disabled)');

    // Tri des stocks par date d'entrée
    availableStocks.sort(function(a, b) {
        var entryDateA = new Date($(a).data("entry-date"));
        var entryDateB = new Date($(b).data("entry-date"));
        return entryDateA - entryDateB;
    });

    // décocher toutes les cases cochées
    resetInitialExitStock(clicked_element);

    // Initialiser le total du volume de sortie
    var totalExitVolume = 0;

    var product_dimensions = $(clicked_element).closest(".added_product").find(".product-exit-item-product-id option:selected").data("dimensions")

    // Parcourir toutes les quantités pour chaque type d'unité
    for (var packageIndex = 0; packageIndex < quantities.length; packageIndex++) {
        var package_type = quantities[packageIndex];
        var unitId = package_type.unit_id;
        var requiredQuantity = package_type.quantities;
        var dimension_unit_product = product_dimensions.find(function(c) {
            return c["unit_quantity_id"] == unitId;
        });
        var unitAbundance = dimension_unit_product.volume;

        // Sélectionner les stocks nécessaires en fonction des quantités requises
        $(availableStocks).each(function(i, stock) {
            var stockQuantity = $(stock).data(`stock-${unitId}`);
            
            if (stockQuantity && requiredQuantity > 0) {
                var selectableQuantity = Math.min(stockQuantity, requiredQuantity);
                requiredQuantity -= selectableQuantity;
                // Calculer le volume pour l'unité actuelle et l'ajouter au total
                var exitVolumeForUnit = selectableQuantity * unitAbundance;

                totalExitVolume += exitVolumeForUnit;

                // Mettre à jour la quantité initiale du stock pour refléter la quantité sélectionnée
                $(stock).data(`initial-stock-${unitId}`, $(stock).data(`initial-stock-${unitId}`) - selectableQuantity);
                if(selectableQuantity > 0) {
                    $(stock).parent().find(".potential-withdrawal").text(selectableQuantity);
                    $(stock).prop('checked', true);
                
                    // Ajout d'un champ caché pour stocker la quantité sélectionnée
                    var hiddenInputName = `selected_quantities[${$(stock).val()}]`;
                    $(stock).parent().find(`input[name="${hiddenInputName}"]`).remove();  // Supprime d'abord le champ caché existant pour ce stock
                    $(stock).parent().append(`<input type="hidden" class="selected_quantities_on_stock" data-unit-id="${unitId}" name="${hiddenInputName}" value="${selectableQuantity}">`);
                }
            }
        });
    }

    updateTotalExitVolume(totalExitVolume, clicked_element);
}

function updateTotalExitVolume(totalVolume, clicked_element) { 
    var div_added_product = $(clicked_element).closest(".added_product");
    var totalVolumeDiv = $(div_added_product).find(".total_volume");
    //on passe le volume en m3
    totalVolume = totalVolume / 1000000;
    var volumeText = "Volume total de sortie : " + totalVolume.toFixed(2) + "m³"; // Format le texte avec 2 décimales
    totalVolumeDiv.text(volumeText);

    var exitUnitAbundances = $(div_added_product).find("input[name*=quantity_by_type][data-unit-abundance]")
    var exitUnitText = ""


    $(exitUnitAbundances).each(
        function(i, input){
            var exitUnitAbundance = $(input).data("unit-abundance");
            var exitUnitName = $(input).data("unit-name");
            // Mettre à jour l'équivalent en unité de sortie

            if(exitUnitAbundance != '' && !isNaN(exitUnitAbundance)){
                // Tu dois avoir une correspondance entre l'abondance et l'unité de sortie quelque part
                var exitUnitEquivalent = Math.ceil(totalVolume / exitUnitAbundance); // exitUnitAbundance doit être défini en tant que l'abondance de l'unité de sortie
                exitUnitText += "Équivalent en unité de sortie " + exitUnitName + " : " + parseInt(exitUnitEquivalent) + "\n"; // Format le texte avec 2 décimales
            }
        }
    );

    var exitUnitDiv = $(div_added_product).find(".exit_unit_equivalent");
    exitUnitDiv.text(exitUnitText);

    // Mettre à jour le total et la couleur dans la div
    var totalFilledDiv = $(".total_filled");
    var filledText = "Total sortie par unité : ";
    var allFilled = true;
 
    var selected_quantities = getQuantitiesSelectedOnExit(div_added_product);
    var wanted_quantities = getQuantitiesWantedOnExit(div_added_product);

    $(wanted_quantities).each(
        function(i, wanted_quantity){
            var selecteds = selected_quantities.find(function(q){ return q.unit_id == wanted_quantity.unit_id });
            if(selecteds == "undefined" || selecteds.quantity != wanted_quantity.quantity){
                allFilled = false;
                filledText += (selecteds.quantity != undefined ? selecteds.quantity : '0') + " " + wanted_quantity.unit_name + " ";
            }
            else{
                filledText += selecteds.quantity + " " + wanted_quantity.unit_name + " ";
            }
        }
    )
 
     totalFilledDiv.text(filledText);
 
     if (allFilled) {
         totalFilledDiv.removeClass("red_c").addClass("green_c");
     } else {
         totalFilledDiv.removeClass("green_c").addClass("red_c");
     }
}

function getQuantitiesSelectedOnExit(div_added_product){
    var ipt_selected_quantities = $(div_added_product).find(".selected_quantities_on_stock");
    var selected_quantities = [];
    $(ipt_selected_quantities).each(
        function(){
            if($(this).val() != "" && parseInt($(this).val()) > 0){
                var unitIdIpt = $(this).data("unit-id");
                var prev_quantities = selected_quantities.find(function(q) { return q.unit_id == unitIdIpt });
                var quantityIpt = parseInt($(this).val());
                if(prev_quantities != undefined){
                    prev_quantities.quantity += quantityIpt;
                }
                else{
                    selected_quantities.push({unit_id : $(this).data("unit-id"), quantity : quantityIpt });
                }
            }
        }
    )

    return selected_quantities;
}

function getQuantitiesWantedOnExit(div_added_product){
    var ipt_wanted_quantities = $(div_added_product).find("input[name*=quantity_by_type]");
    var wanted_quantities = [];
    $(ipt_wanted_quantities).each(
        function(){
            if($(this).val() != "" && parseInt($(this).val()) > 0){
                wanted_quantities.push({unit_id : $(this).data("unit-id"), quantity : $(this).val(), unit_name : $(this).data("unit-name") });
            }
        }
    )

    return wanted_quantities;
}

function selectStockManually(quantities, clicked_element){
    for (var packageIndex = 0; packageIndex < quantities.length; packageIndex++) {
        var package_type = quantities[packageIndex];
        var unitId = package_type.unit_id;
        var requiredQuantity = package_type.quantities;

        var stock = $(clicked_element);
        var alreadySelectedQuantity = 0;
        $(stock).closest(".added_product").find(".selected_quantities_on_stock[data-unit-id='"+unitId+"']").each(
            function(){
                alreadySelectedQuantity = $(this).val();
            }
        )
        requiredQuantity = requiredQuantity - alreadySelectedQuantity;

        // Sélectionner les stocks nécessaires en fonction des quantités requises
        var stockQuantity = $(stock).data(`stock-${unitId}`);
        
        if (stockQuantity && requiredQuantity > 0) {
            var selectableQuantity = Math.min(stockQuantity, requiredQuantity);
            requiredQuantity -= selectableQuantity;

            // Mettre à jour la quantité initiale du stock pour refléter la quantité sélectionnée
            $(stock).data(`initial-stock-${unitId}`, $(stock).data(`initial-stock-${unitId}`) - selectableQuantity);
            if(selectableQuantity > 0) {
                $(stock).parent().find(".potential-withdrawal").text(selectableQuantity);
                $(stock).prop('checked', true);
            
                // Ajout d'un champ caché pour stocker la quantité sélectionnée
                var hiddenInputName = `selected_quantities[${$(stock).val()}]`;
                $(stock).parent().find(`input[name="${hiddenInputName}"]`).remove();  // Supprime d'abord le champ caché existant pour ce stock
                $(stock).parent().append(`<input type="hidden" class="selected_quantities_on_stock" data-unit-id="${unitId}" name="${hiddenInputName}" value="${selectableQuantity}">`);
            }
        }
        else{
            $(clicked_element)[0].checked = false;
        }
    }
}

// function selectStockFifo(quantities, traversal_type, clicked_element) {
//     var availableStocks = $(clicked_element).closest(".table_warehouse").find('input[name="selected_stocks[]"]:not(:disabled)');

//     // Tri des stocks par date d'entrée
//     availableStocks.sort(function(a, b) {
//         var entryDateA = new Date($(a).data("entry-date"));
//         var entryDateB = new Date($(b).data("entry-date"));
//         return entryDateA - entryDateB;
//     });

//     // décocher toutes les cases cochées
//     availableStocks.prop('checked', false);

//     // Parcourir toutes les quantités pour chaque type d'unité
//     for (var packageIndex = 0; packageIndex < quantities.length; packageIndex++) {
//         var package_type = quantities[packageIndex];
//         var unitId = package_type.unit_id;
//         var requiredQuantity = package_type.quantities;

//         if (traversal_type === 'horizontal') {
//             var checkedIndex = availableStocks.index(clicked_element);
//             for (var i = checkedIndex; i < availableStocks.length && requiredQuantity > 0; i++) {
//                 var stock = availableStocks[i];
//                 var stockQuantity = $(stock).data(`stock-${unitId}`);

//                 if (stockQuantity && requiredQuantity > 0) {
//                     var selectableQuantity = Math.min(stockQuantity, requiredQuantity);
//                     requiredQuantity -= selectableQuantity;

//                     // Mettre à jour la quantité initiale du stock pour refléter la quantité sélectionnée
//                     $(stock).data(`initial-stock-${unitId}`, $(stock).data(`initial-stock-${unitId}`) - selectableQuantity);
//                     if (selectableQuantity > 0) {
//                         $(stock).prop('checked', true);
//                     }
//                 }
//             }
//         } else {  // Pour le parcours vertical
//             var aisles = $(clicked_element).closest(".table_warehouse").find('.td_aisle');
//             var checkedAisle = $(clicked_element).closest('.td_aisle');
//             var checkedAisleIndex = aisles.index(checkedAisle);
//             var checkedLevel = $(clicked_element).closest('.level_col');
//             var checkedLevelIndex = checkedAisle.find('.level_col').index(checkedLevel);
//             var checkedLocation = $(clicked_element).closest('tr');
//             var checkedLocationIndex = checkedLevel.find('tr').index(checkedLocation);

//             for (var a = checkedAisleIndex; a < aisles.length && requiredQuantity > 0; a++) {
//                 var aisle = aisles.eq(a);
//                 var levels = aisle.find('.level_col');
//                 var startLocationIndex = (a === checkedAisleIndex) ? checkedLocationIndex : 0;

//                 for (var l = startLocationIndex; l < levels.length && requiredQuantity > 0; l++) {
//                     if (l > startLocationIndex) {
//                         checkedLevelIndex = 0;
//                     }
//                     var level = levels.eq(l);
//                     var locations = level.find('tr');
//                     var startLocationIndex = (l === checkedLevelIndex) ? checkedLocationIndex : 1;

//                     for (var loc = startLocationIndex; loc < locations.length && requiredQuantity > 0; loc++) {
//                         var location = locations.eq(loc);
//                         var stocks = location.find(`input[data-stock-${unitId}]`);

//                         stocks.each(function() {
//                             var stock = $(this);
//                             var stockQuantity = stock.data(`stock-${unitId}`);

//                             if (stockQuantity && requiredQuantity > 0) {
//                                 var selectableQuantity = Math.min(stockQuantity, requiredQuantity);
//                                 requiredQuantity -= selectableQuantity;

//                                 // Mettre à jour la quantité initiale du stock pour refléter la quantité sélectionnée
//                                 stock.data(`initial-stock-${unitId}`, stock.data(`initial-stock-${unitId}`) - selectableQuantity);
//                                 if (selectableQuantity > 0) {
//                                     stock.prop('checked', true);
//                                 }
//                             }
//                         });
//                     }
//                 }
//             }
//         }
//     }
// }


function selectStockLifo(quantities, traversal_type, clicked_element) {
    // obtenir tous les stocks disponibles
    var availableStocks = $(clicked_element).closest(".table_warehouse").find('input[name="selected_stocks[]"]:not(:disabled)');

    // Tri des stocks par date d'entrée (dans l'ordre inverse)
    availableStocks.sort(function(a, b) {
        var entryDateA = new Date($(a).data("entry-date"));
        var entryDateB = new Date($(b).data("entry-date"));
        return entryDateB - entryDateA; // Notez l'inversion de l'ordre
    });

    // décocher toutes les cases cochées
    resetInitialExitStock(clicked_element);

    // Même logique que pour FIFO pour parcourir les quantités et sélectionner les stocks
    for (var packageIndex = 0; packageIndex < quantities.length; packageIndex++) {
        var package_type = quantities[packageIndex];
        var unitId = package_type.unit_id;
        var requiredQuantity = package_type.quantities;

        $(availableStocks).each(function(i, stock) {
            var stockQuantity = $(stock).data(`stock-${unitId}`);
            
            if (stockQuantity && requiredQuantity > 0) {
                var selectableQuantity = Math.min(stockQuantity, requiredQuantity);
                requiredQuantity -= selectableQuantity;

                $(stock).data(`initial-stock-${unitId}`, $(stock).data(`initial-stock-${unitId}`) - selectableQuantity);
                if(selectableQuantity > 0) {
                    $(stock).prop('checked', true);
                }
            }
        });
    }
}

// function selectStockLifo(quantities, traversal_type, clicked_element) {
//     var availableStocks = $(clicked_element).closest(".table_warehouse").find('input[name="selected_stocks[]"]:not(:disabled)');

//     // Tri des stocks par date d'entrée (dans l'ordre inverse)
//     availableStocks.sort(function(a, b) {
//         var entryDateA = new Date($(a).data("entry-date"));
//         var entryDateB = new Date($(b).data("entry-date"));
//         return entryDateB - entryDateA;
//     });

//     // décocher toutes les cases cochées
//     availableStocks.prop('checked', false);

//     for (var packageIndex = 0; packageIndex < quantities.length; packageIndex++) {
//         var package_type = quantities[packageIndex];
//         var unitId = package_type.unit_id;
//         var requiredQuantity = package_type.quantities;

//         if (traversal_type === 'horizontal') {
//             var checkedIndex = availableStocks.index(clicked_element);
//             for (var i = checkedIndex; i >= 0 && requiredQuantity > 0; i--) {
//                 var stock = availableStocks[i];
//                 var stockQuantity = $(stock).data(`stock-${unitId}`);

//                 if (stockQuantity && requiredQuantity > 0) {
//                     var selectableQuantity = Math.min(stockQuantity, requiredQuantity);
//                     requiredQuantity -= selectableQuantity;
//                     $(stock).data(`initial-stock-${unitId}`, $(stock).data(`initial-stock-${unitId}`) - selectableQuantity);
//                     if (selectableQuantity > 0) {
//                         $(stock).prop('checked', true);
//                     }
//                 }
//             }
//         } else {  // Pour le parcours vertical
//             var aisles = $(clicked_element).closest(".table_warehouse").find('.td_aisle');
//             var checkedAisle = $(clicked_element).closest('.td_aisle');
//             var checkedAisleIndex = aisles.index(checkedAisle);
//             var checkedLevel = $(clicked_element).closest('.level_col');
//             var checkedLevelIndex = checkedAisle.find('.level_col').index(checkedLevel);
//             var checkedLocation = $(clicked_element).closest('tr');
//             var checkedLocationIndex = checkedLevel.find('tr').index(checkedLocation);

//             for (var a = checkedAisleIndex; a >= 0 && requiredQuantity > 0; a--) {
//                 var aisle = aisles.eq(a);
//                 var levels = aisle.find('.level_col');
//                 var endLocationIndex = (a === checkedAisleIndex) ? checkedLocationIndex : levels.length - 1;

//                 for (var l = endLocationIndex; l >= 0 && requiredQuantity > 0; l--) {
//                     if (l < endLocationIndex) {
//                         checkedLevelIndex = levels.length - 1;
//                     }
//                     var level = levels.eq(l);
//                     var locations = level.find('tr');
//                     var endLocationIndex = (l === checkedLevelIndex) ? checkedLocationIndex : locations.length - 1;

//                     for (var loc = endLocationIndex; loc >= 0 && requiredQuantity > 0; loc--) {
//                         var location = locations.eq(loc);
//                         var stocks = location.find(`input[data-stock-${unitId}]`);

//                         stocks.each(function() {
//                             var stock = $(this);
//                             var stockQuantity = stock.data(`stock-${unitId}`);

//                             if (stockQuantity && requiredQuantity > 0) {
//                                 var selectableQuantity = Math.min(stockQuantity, requiredQuantity);
//                                 requiredQuantity -= selectableQuantity;
//                                 stock.data(`initial-stock-${unitId}`, stock.data(`initial-stock-${unitId}`) - selectableQuantity);
//                                 if (selectableQuantity > 0) {
//                                     stock.prop('checked', true);
//                                 }
//                             }
//                         });
//                     }
//                 }
//             }
//         }
//     }
// }


function selectStockFefo(quantities, traversal_type, clicked_element) {
    // obtenir tous les stocks disponibles
    var availableStocks = $(clicked_element).closest(".table_warehouse").find('input[name="selected_stocks[]"]:not(:disabled)');

    // Tri des stocks par date d'expiration
    availableStocks.sort(function(a, b) {
        var expiryDateA = new Date($(a).data("expiry-date"));
        var expiryDateB = new Date($(b).data("expiry-date"));
        return expiryDateA - expiryDateB;
    });

    // décocher toutes les cases cochées
    resetInitialExitStock(clicked_element);

    // Même logique que pour FIFO pour parcourir les quantités et sélectionner les stocks
    for (var packageIndex = 0; packageIndex < quantities.length; packageIndex++) {
        var package_type = quantities[packageIndex];
        var unitId = package_type.unit_id;
        var requiredQuantity = package_type.quantities;

        $(availableStocks).each(function(i, stock) {
            var stockQuantity = $(stock).data(`stock-${unitId}`);
            
            if (stockQuantity && requiredQuantity > 0) {
                var selectableQuantity = Math.min(stockQuantity, requiredQuantity);
                requiredQuantity -= selectableQuantity;

                $(stock).data(`initial-stock-${unitId}`, $(stock).data(`initial-stock-${unitId}`) - selectableQuantity);
                if(selectableQuantity > 0) {
                    $(stock).prop('checked', true);
                }
            }
        });
    }
}

function makeElementsDraggable() {
    $(".stock-display").draggable({
        revert: 'invalid', // cela garantit que le stock retournera à son emplacement initial si non déposé sur un emplacement valide
        helper: 'clone',    // crée un clone de l'élément lors du déplacement
        start: function(event, ui) {
            // Stockez l'emplacement source dans une variable ou dans l'élément draggable lui-même
            $(this).data('source-location-id', $(this).closest('.td_location').data('location-id'));
        }
    });
}

function resetInitialExitStock(clicked_element){
    var availableStocks = $(clicked_element).closest(".table_warehouse").find('input[name="selected_stocks[]"]:not(:disabled)');
    availableStocks.prop('checked', false);
    $(availableStocks).each(
        function(i, stock){
            var quantities_to_restore = $(stock).parent().find(".selected_quantities_on_stock");
            $(quantities_to_restore).each(
                function(i, quantity){
                    var unit_id = $(quantity).data("unit-id");
                    var quantity = parseInt($(quantity).val());
                    $(stock).data("stock-" + unit_id, parseInt($(stock).data("stock-" + unit_id))+parseInt(quantity));
                    $(stock).data("initial-stock-" + unit_id, parseInt($(stock).data("stock-" + unit_id))+parseInt(quantity));
                    $(stock).parent().find(".stock-quantity-"+ unit_id).text($(stock).data("stock-" + unit_id));
                }
            );

            $(stock).parent().find(".potential-withdrawal").text("");
            $(stock).parent().find(".selected_quantities_on_stock").remove();
        }
    );
    
}

// function selectStockFefo(quantities, traversal_type, clicked_element) {
//     var availableStocks = $(clicked_element).closest(".table_warehouse").find('input[name="selected_stocks[]"]:not(:disabled)');

//     // Tri des stocks par date d'expiration
//     availableStocks.sort(function(a, b) {
//         var expiryDateA = new Date($(a).data("expiry-date"));
//         var expiryDateB = new Date($(b).data("expiry-date"));
//         return expiryDateA - expiryDateB;
//     });

//     // décocher toutes les cases cochées
//     availableStocks.prop('checked', false);

//     for (var packageIndex = 0; packageIndex < quantities.length; packageIndex++) {
//         var package_type = quantities[packageIndex];
//         var unitId = package_type.unit_id;
//         var requiredQuantity = package_type.quantities;

//         if (traversal_type === 'horizontal') {
//             var checkedIndex = availableStocks.index(clicked_element);
//             for (var i = checkedIndex; i < availableStocks.length && requiredQuantity > 0; i++) {
//                 var stock = availableStocks[i];
//                 var stockQuantity = $(stock).data(`stock-${unitId}`);

//                 if (stockQuantity && requiredQuantity > 0) {
//                     var selectableQuantity = Math.min(stockQuantity, requiredQuantity);
//                     requiredQuantity -= selectableQuantity;
//                     $(stock).data(`initial-stock-${unitId}`, $(stock).data(`initial-stock-${unitId}`) - selectableQuantity);
//                     if (selectableQuantity > 0) {
//                         $(stock).prop('checked', true);
//                     }
//                 }
//             }
//         } else {  // Pour le parcours vertical
//             var aisles = $(clicked_element).closest(".table_warehouse").find('.td_aisle');
//             var checkedAisle = $(clicked_element).closest('.td_aisle');
//             var checkedAisleIndex = aisles.index(checkedAisle);
//             var checkedLevel = $(clicked_element).closest('.level_col');
//             var checkedLevelIndex = checkedAisle.find('.level_col').index(checkedLevel);
//             var checkedLocation = $(clicked_element).closest('tr');
//             var checkedLocationIndex = checkedLevel.find('tr').index(checkedLocation);

//             for (var a = checkedAisleIndex; a < aisles.length && requiredQuantity > 0; a++) {
//                 var aisle = aisles.eq(a);
//                 var levels = aisle.find('.level_col');
//                 var startLocationIndex = (a === checkedAisleIndex) ? checkedLocationIndex : 0;

//                 for (var l = startLocationIndex; l < levels.length && requiredQuantity > 0; l++) {
//                     if (l > startLocationIndex) {
//                         checkedLevelIndex = 0;
//                     }
//                     var level = levels.eq(l);
//                     var locations = level.find('tr');
//                     var startLocationIndex = (l === checkedLevelIndex) ? checkedLocationIndex : 1;

//                     for (var loc = startLocationIndex; loc < locations.length && requiredQuantity > 0; loc++) {
//                         var location = locations.eq(loc);
//                         var stocks = location.find(`input[data-stock-${unitId}]`);

//                         stocks.each(function() {
//                             var stock = $(this);
//                             var stockQuantity = stock.data(`stock-${unitId}`);

//                             if (stockQuantity && requiredQuantity > 0) {
//                                 var selectableQuantity = Math.min(stockQuantity, requiredQuantity);
//                                 requiredQuantity -= selectableQuantity;
//                                 stock.data(`initial-stock-${unitId}`, stock.data(`initial-stock-${unitId}`) - selectableQuantity);
//                                 if (selectableQuantity > 0) {
//                                     stock.prop('checked', true);
//                                 }
//                             }
//                         });
//                     }
//                 }
//             }
//         }
//     }
// }

let isToastDisplayed = false;

// Fonction pour afficher un toast uniquement si aucun n'est actuellement affiché
function showToast(message, classes) {
  if (!isToastDisplayed) {
    M.toast({
      html: message,
      classes: classes,
      completeCallback: function() {
        // Réinitialiser lorsque le toast est fermé ou a terminé l'affichage
        isToastDisplayed = false;
      }
    });
    // Marquez qu'un toast est affiché
    isToastDisplayed = true;
  }
}

function updateSubmitButtonState() {
    const titleField = $('input[name="inventory[title]"]');
    const productSelect = $('select[name="inventory[product_ids][]"]');
    const warehouseSelect = $('select[name="inventory[warehouse_ids][]"]');
    const submitButton = $('#submit-inventory');
  
    const titleFilled = titleField.val() !== '';
    const productsSelected = productSelect.find('option:selected').length > 0;
    const warehousesSelected = warehouseSelect.find('option:selected').length > 0;
    const isFormValid = titleFilled && (productsSelected || warehousesSelected);

    if (!titleFilled) {
        $('#inventory_modal-title_error').addClass("error").removeClass("valid");
        $('#inventory_modal-title_error').find(".material-icons").text("warning");
    } else {
        $('#inventory_modal-title_error').addClass("valid").removeClass("error");
        $('#inventory_modal-title_error').find(".material-icons").text("check");
    }
    
    if (!productsSelected && !warehousesSelected) {
        $('#inventory_modal-products_warehouses_error').addClass("error").removeClass("valid");
        $('#inventory_modal-products_warehouses_error').find(".material-icons").text("warning");
    } else {
        $('#inventory_modal-products_warehouses_error').addClass("valid").removeClass("error");
        $('#inventory_modal-products_warehouses_error').find(".material-icons").text("check");
    }

    if (isFormValid) {
        submitButton.removeClass('disabled').prop('disabled', false);
    } else {
        submitButton.addClass('disabled').prop('disabled', true);
    }
}

function compareTableRow(rowA, rowB, columnIndex, asc) {
    const getValue = tr => tr.children[columnIndex].innerText || tr.children[columnIndex].textContent;
    const a = getValue(rowA);
    const b = getValue(rowB);

    // Modification pour comparer numériquement ou alphabétiquement
    let comparison = 0;
    if (!isNaN(parseFloat(a)) && !isNaN(parseFloat(b))) {
        // Pour les nombres
        comparison = parseFloat(a) - parseFloat(b);
    } else {
        // Pour les chaînes de caractères
        comparison = a.toString().localeCompare(b.toString());
    }
    return comparison * (asc ? 1 : -1);
}

// Encapsulez le code d'initialisation des menus déroulants dans une fonction
function initSidebarAndDropdowns() {
    const sideNavs = document.querySelectorAll('.sidenav');
    var sidenavOptions = {
        edge:"right"
    }

    const sideNavInstances = M.Sidenav.init(sideNavs, sidenavOptions);

    if (window.innerWidth <= 600) {
        document.querySelector('.navbar-fixed').style.position = 'relative';
    }

    var options = {
        constrainWidth: false,
        coverTrigger: true,
        hover: true
    };
    
    var dropdowns = document.querySelectorAll('.dropdown-trigger-menu');
    var instances = M.Dropdown.init(dropdowns, options);
    
    // Attacher des événements pour chaque dropdown
    // dropdowns.forEach(function(dropdown) {
    //     dropdown.addEventListener('mouseover', function() {
    //       var instance = M.Dropdown.getInstance(dropdown);
    //       instance.open();
    //     });
    
        // $(document).on("mouseleave", "#settings-dropdown", function(){
        //     var instance = M.Dropdown.getInstance(dropdown);
        //     instance.close();
        // });
      
    //});

    var select_options = {
        dropdownOptions: {
          constrainWidth: true,
          hover: false
        }
    }
    var selectElems  = document.querySelectorAll('select');
    var selectInstances  = M.FormSelect.init(selectElems , select_options);


    const defaultIcons = document.querySelectorAll(".default-icon");

    document.querySelectorAll('.responsive-table th').forEach(function(th) {
        th.addEventListener('click', function() {
            // Sélectionne toutes les lignes de détails
            var detailRows = document.querySelectorAll('.detail-row');
            // Parcourt chaque ligne de détail
            detailRows.forEach(function(row) {
                // Si la ligne est visible, la cache
                if (!row.classList.contains('none')) {
                    row.classList.add('none');
                }
            });
        });
    });

    defaultIcons.forEach((icon) => {
        icon.addEventListener("click", (event) => {
        const url = event.target.dataset.url;
        if (url) {
            fetch(url, {
                    method: "PUT",
                    headers: {
                        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
                        "Content-Type": "application/json",
                    },
                    credentials: "same-origin",
                })
                .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Une erreur s'est produite lors de la mise à jour du compte par défaut.");
                }
                })
                .then((data) => {
                    sessionStorage.setItem("notificationMessage", data.notice);
                    location.reload();
                })
                .catch((error) => {
                    showToast(error.message, "red")
                });
            }
        });
    });

    // $('.order-row').on('click', function() {
    //     var target = $(this).data('target');
    //     $('#' + target).toggle(); // bascule entre l'affichage et le masquage du détail
    // });

    // Fonction pour basculer les détails et l'icône d'expansion
    function toggleDetails(rowClass, detailIdPrefix) {
        $(document).off("click", `.${rowClass}`);
        $(document).on("click", `.${rowClass}`, function() {
            const id = this.getAttribute('data-target');
            const details = $(`#${detailIdPrefix}-${id}`);
            const icon = this.querySelector('.expand-icon');

            details.toggleClass("none");
            icon.textContent = details.hasClass("none") ? 'expand_more' : 'expand_less';

            // $(details).find("table").css("border", "1px dashed #ccc");
            $(details).find("th").css("background", "initial");
            $(this).css("border-bottom", details.hasClass("none") ? "1px solid rgba(0, 0, 0, 0.12)" : "none");
        });
    }

    // Appel de la fonction pour chaque catégorie de lignes
    toggleDetails("flow-row", "flow");
    toggleDetails("location-row", "location");
    toggleDetails("inventory-row", "inventory");
    toggleDetails("order-row", "order");

    $(document).off("change", ".slc_price_type");
    $(document).on("change", ".slc_price_type", 
        function(){
            if($(this).val() == "inventory"){
                $(this).parent().parent().find(".slc_unit_quantity_price")[0].disabled = true;
            }
            else{
                $(this).parent().parent().find(".slc_unit_quantity_price")[0].disabled = false;
            }
        }
    );

    var elems = document.querySelectorAll('.datepicker');
    if(elems.length > 0){
        var instances = M.Datepicker.init(elems, {format: 'yyyy-mm-dd'});
    }

    $(".slc_price_type").each(
        function(i, ipt_price_type){
            var price_type = $(ipt_price_type).val();
            if(price_type == "inventory"){
                $(ipt_price_type).parent().parent().find(".slc_unit_quantity_price")[0].disabled = true;
            }
        }
    );

    $(document).off("change", ".product_entry_item_product_id");
    $(document).on("change", ".product_entry_item_product_id", 
        function(){
            var product_select = $(this);
            var productId = $(this).val();
            setEntryAvailableLocations(productId, product_select, false);

            var selectedOption = this.options[this.selectedIndex];
            var defaultProviderId = $(selectedOption).data('default-provider-id');
            $(this).parent().parent().find(".slc_provider").val(defaultProviderId); 

            refreshExpeditionLimiteDate($(this).closest(".added_product"));
        }
    );

    $(document).off("change", "input[name*='best_before_date']");
    $(document).on("change", "input[name*='best_before_date']", 
        function(){
            refreshExpeditionLimiteDate($(this).closest(".added_product"));
        }
    );

    $(document).off("change", "#product_entry_customer_id");
    $(document).on("change", "#product_entry_customer_id", 
        function(){
            $(".added_product").each(
                function(i, added_product){
                    refreshExpeditionLimiteDate($(this).closest(".added_product"));
                }
            );

            updateEntryTitle();
        }
    );

    $(document).off("change", "#product_entry_entry_date");
    $(document).on("change", "#product_entry_entry_date", 
        function(){
            updateEntryTitle();
        }
    );
    
    $(document).off("change", "#product_exit_customer_id");
    $(document).on("change", "#product_exit_customer_id", 
        function(){
            updateExitTitle();
        }
    );

    $(document).off("change", "#product_exit_exit_date");
    $(document).on("change", "#product_exit_exit_date", 
        function(){
            updateExitTitle();
        }
    );

    $(document).off("change", "#product_transfer_customer_id");
    $(document).on("change", "#product_transfer_customer_id", 
        function(){
            updateTransferTitle();
        }
    );

    $(document).off("change", "#product_transfer_transfer_date");
    $(document).on("change", "#product_transfer_transfer_date", 
        function(){
            updateTransferTitle();
        }
    );

    $(".added_product").each(
        function(i, div_added_product){
            addWarehouseToTabCells(div_added_product);
        }
    );

    $(".product_entry_item_product_id").each(
        function(i, product_entry_item_product_list){
            if($(product_entry_item_product_list).val() > 0 && $(product_entry_item_product_list).closest(".added_product").find(".table_warehouse").length == 0){
                setEntryAvailableLocations($(product_entry_item_product_list).val(), $(product_entry_item_product_list, true));
            }
        }
    );

    $(document).off("change", "#order_business_id");
    $(document).on("change", "#order_business_id",
        function(){
            var customer_id = $(this).val();
            $(".added_product").each(
                function(i, div_added_product){
                    var product_select = $(this).find(".product-exit-item-product-id");
                    var productId = $(product_select).val();
        
                    $.ajax({
                        url: '/products/' + productId + '/product_unit_associations?customer_id=' + customer_id,
                        method: 'GET',
                        dataType: 'json',
                        success: function(data) {
                          var quantitiesDiv = $(product_select).closest(".added_product").find('.quantities_by_package');
                          quantitiesDiv.empty();
                          data.forEach(function(unit) {
                            var html = `
                              <div class="col s3">
                                <label>${unit.name} quantity</label>
                                <input type="text" name="quantity_by_type[${unit.id}]" 
                                       data-unit-id="${unit.id}" data-unit-name="${unit.name}" 
                                       data-unit-abundance="${unit.abundance || ''}">
                              </div>
                            `;
                            quantitiesDiv.append(html);
                          });
                        }
                    });      
                }
            )
        }
    );

    $(document).off("change", ".product-exit-item-product-id");
    $(document).on("change", ".product-exit-item-product-id",
        function(){
            var product_select = $(this);
            var productId = $(this).val();
            var customer_id = $("#order_business_id").val();

            if (!productId || productId === "0" || productId === undefined) {
                product_select.closest(".added_product").find('.warehouse_layout').empty();
                return;
            }

            // Affiche le loader
            var loaderHtml = '<div class="product_loader"><div class="progress"><div class="indeterminate"></div></div></div>';
            product_select.closest(".added_product").find('.warehouse_layout').html(loaderHtml);

            $.ajax({
                url: '/products/' + productId + '/product_unit_associations?customer_id=' + customer_id,
                method: 'GET',
                dataType: 'json',
                success: function(data) {
                  var quantitiesDiv = $(product_select).closest(".added_product").find('.quantities_by_package');
                  quantitiesDiv.empty();
                  data.forEach(function(unit) {
                    var html = `
                      <div class="col s3">
                        <label>${unit.name} quantity</label>
                        <input type="text" name="quantity_by_type[${unit.id}]" 
                               data-unit-id="${unit.id}" data-unit-name="${unit.name}" 
                               data-unit-abundance="${unit.abundance || ''}">
                      </div>
                    `;
                    quantitiesDiv.append(html);
                  });
                }
            });
              

            $.ajax({  
                url: '/products/' + productId + '/available_stocks',
                method: 'GET',
                dataType: 'html',
                success: function(data) {
                    if ($(data).hasClass('no-stock-alert')) {
                        var productIndex = $(".added_product").index($(product_select).closest(".added_product"));
                        $(product_select).closest(".added_product").find('.warehouse_layout').html(data);

                        // Ajoutez un champ caché pour enregistrer que le stock n'était pas disponible pour ce product_exit_item spécifique
                        $('<input>').attr({
                            type: 'hidden',
                            name: 'product_exit[product_exit_items_attributes][' + productIndex + '][no_stock]',
                            value: 'true'
                        }).appendTo($(product_select).closest('.added_product'));
                    } else {
                        $(product_select).closest(".added_product").find('.warehouse_layout').html(data);
                        $(product_select).closest(".added_product").find(".storage_type").show();
                        resize_warehouse();
                    }
                    product_select.closest(".added_product").find('.product_loader').remove();
                }
            });
        }
    );

    $(document).off("change", ".product-transfer-item-product-id");
    $(document).on("change", ".product-transfer-item-product-id",
        function(){
            var product_select = $(this);
            var productId = $(this).val();

            $.ajax({  
                url: '/products/' + productId + '/available_locations_and_stocks',
                method: 'GET',
                dataType: 'html',
                success: function(data) {
                    $(product_select).closest(".added_product").find('.warehouse_layout').html(data);
                    $(product_select).closest(".added_product").find(".storage_type").show();
                    resize_warehouse();

                    // Faire en sorte que .stock-display soit déplaçable
                    $(".stock-display").draggable({
                        revert: 'invalid', // cela garantit que le stock retournera à son emplacement initial si non déposé sur un emplacement valide
                        helper: 'clone',    // crée un clone de l'élément lors du déplacement
                        start: function(event, ui) {
                            // Stockez l'emplacement source dans une variable ou dans l'élément draggable lui-même
                            $(this).data('source-location-id', $(this).closest('.td_location').data('location-id'));
                        }
                    });

                    // Faire en sorte que .td_location accepte les éléments .stock-display déplacés
                    $(".td_location").droppable({
                        accept: ".stock-display",
                        drop: function(event, ui) {
                            var draggedStockUnits = ui.draggable.find(".stock-quantity");
                            var isDroppable = true;
                        
                            // Créer des champs input pour chaque unité de stock
                            var inputsHtml = '';
                            draggedStockUnits.each(function() {
                                var unitId = $(this).data('unit-id');
                                var quantity = parseInt($(this).text(), 10);
                                inputsHtml += '<label for="unit-' + unitId + '">Quantité pour unité ' + unitId + ': </label>';
                                inputsHtml += '<input type="number" id="unit-' + unitId + '" max="' + quantity + '" value="' + quantity + '"><br>';
                            });
                            $("#stock-transfer-dialog").html(inputsHtml);
                        
                            // Afficher la boîte de dialogue
                            $("#stock-transfer-dialog").dialog({
                                modal: true,
                                buttons: {
                                    "Confirmer": function() {
                                        draggedStockUnits.each(function() {
                                            var unitId = $(this).data('unit-id');
                                            var requestedQuantity = parseInt($("#unit-" + unitId).val(), 10);
                                        
                                            var capacitySpan = $(event.target).find(`.span-capacity-quantity[data-unit-id=${unitId}]`);
                                        
                                            // Si l'emplacement n'a pas de capacité pour cette unité, on considère qu'il a une capacité de 0
                                            var availableCapacity = capacitySpan.length ? parseInt(capacitySpan.text(), 10) : 0;
                                        
                                            if (requestedQuantity > availableCapacity) {
                                                console.log("Not enough capacity for unit:", unitId);
                                                isDroppable = false;
                                                return false; // break the .each loop
                                            }
                                        });
                        
                                        if (isDroppable) {
                                            draggedStockUnits.each(function() {
                                                var unitId = $(this).data('unit-id');
                                                var requestedQuantity = parseInt($("#unit-" + unitId).val(), 10); // Nous obtenons la quantité demandée à partir du dialogue
                                                
                                                // Ajustez l'espace disponible à l'emplacement d'origine
                                                var sourceCapacitySpan = ui.draggable.closest('.td_location').find(`.span-capacity-quantity[data-unit-id=${unitId}]`);
                                                var availableSourceCapacity = parseInt(sourceCapacitySpan.text(), 10);
                                                sourceCapacitySpan.text(availableSourceCapacity + requestedQuantity); // On ajoute la quantité demandée à l'espace source
                                                
                                                // Ajustez l'espace disponible à l'emplacement cible
                                                var targetCapacitySpan = $(event.target).find(`.span-capacity-quantity[data-unit-id=${unitId}]`);
                                                var availableTargetCapacity = parseInt(targetCapacitySpan.text(), 10);
                                                targetCapacitySpan.text(availableTargetCapacity - requestedQuantity); // On soustrait la quantité demandée à l'espace cible
                                                
                                                // Maintenant, mettez à jour l'affichage du stock déplacé pour refléter la quantité transférée
                                                var originalQuantity = parseInt($(this).text(), 10);
                                                if (requestedQuantity < originalQuantity) {
                                                    // Si seulement une partie du stock est déplacée, créez un nouvel élément pour la quantité déplacée
                                                    var clonedStock = ui.draggable.clone();
                                                    clonedStock.find(`.stock-quantity[data-unit-id=${unitId}]`).text(requestedQuantity);
                                                    $(event.target).append(clonedStock);
                                                    
                                                    // Ajustez la quantité restante dans l'élément d'origine
                                                    $(this).text(originalQuantity - requestedQuantity);
                                                } else {
                                                    // Si tout le stock est déplacé, déplacez simplement l'élément entier
                                                    $(event.target).append(ui.draggable);
                                                }

                                                var stockId = ui.draggable.data('stock-id');
                                                var sourceLocationId = ui.draggable.data('source-location-id');
                                                var targetLocationId = $(event.target).data('location-id');

                                                var transfer_item_index = $(event.target).closest(".nested-fields").index();
                                                // Ajustez le champ caché pour la source (quantité soustraite)
                                                adjustHiddenFieldForLocation(stockId, sourceLocationId, -requestedQuantity, transfer_item_index);
                                                
                                                // Ajustez le champ caché pour la cible (quantité ajoutée)
                                                adjustHiddenFieldForLocation(stockId, targetLocationId, requestedQuantity, transfer_item_index);
        
                                            });

                                            makeElementsDraggable();
                                        } else {
                                            alert("Pas assez d'espace disponible pour l'une ou plusieurs des unités de quantité demandées!");
                                        }
                                        $(this).dialog("close");
                                    },
                                    "Annuler": function() {
                                        $(this).dialog("close");
                                    }
                                }
                            });
                        }
                    });                 
                }
            });
        }
    );

    $(document).off("change", 'input[name="selected_locations[]"]');
    $(document).on("change", 'input[name="selected_locations[]"]', function() {
        if($(this)[0].checked == true){
            $(this)[0].checked = false;
            // Obtenir le type de stockage actuellement sélectionné
            var storageType = $(this).closest(".added_product").find('.storage_type input[type="radio"]:checked').val(); 
        
            var quantities = []
            // Obtenir le nombre de palettes 
            $(this).closest(".added_product").find('input[name="quantity_by_type"]').each(
                function(i, quantity_by_type){
                    quantities.push({ unit_id : $(this).attr("data-unit-id"), quantities : parseInt($(this).val()) })
                }
            )
        
            // Sélectionner les emplacements en fonction du type de stockage
            if (storageType == 'horizontal') {
              // sélectionner les emplacements horizontalement
              selectLocationsHorizontally(quantities, $(this));
            } else if (storageType == 'vertical') {
              // sélectionner les emplacements verticalement
              selectLocationsVertically(quantities, $(this));
            }
        }
        else{
            resetInitialStock($(this));
        }
    });

    $(document).off("change", 'input[name="selected_stocks[]"]');
    $(document).on("change", 'input[name="selected_stocks[]"]', function() {
        var exitType = $(this).closest(".added_product").find('.storage_type input[type="radio"]:checked').val(); 
        if($(this)[0].checked == true){
            // Obtenir le type de stockage actuellement sélectionné
        
            var quantities = []
            // Obtenir le nombre de palettes 
            $(this).closest(".added_product").find('input[name*="quantity_by_type"]').each(
                function(i, quantity_by_type){
                    quantities.push({ unit_id : $(this).attr("data-unit-id"), quantities : parseInt($(this).val()), length : $(this).data("unit-length"), width : $(this).data("unit-width"), height : $(this).data("unit-height")});
                }
            )

            var traversalType = $('input[name="traversal_type"]:checked').val();
        
            // Sélectionner les emplacements en fonction du type de stockage
            if (exitType == 'fifo') {
              // sélectionner les emplacements horizontalement
              selectStockFifo(quantities, traversalType, $(this));
            } else if (exitType == 'lifo') {
              // sélectionner les emplacements verticalement
              selectStockLifo(quantities, traversalType, $(this));
            } else if (exitType == 'fefo') {
                // laisser l'utilisateur sélectionner les emplacements manuellement
              selectStockFefo(quantities, traversalType, $(this));
            }
            else if (exitType == 'manual') {
              selectStockManually(quantities, $(this));
            }
        }
        else if(exitType != 'manual'){
            resetInitialExitStock($(this));
        }
        else{
            $(this).parent().find(".selected_quantities_on_stock").remove();
            $(this).parent().find(".potential-withdrawal").text("");
        }
    });

    $(document).off('change', ".stock-input-units");  
    $(document).on('change', ".stock-input-units", function(event) {
        const currentRow = event.target.closest('tr');
        const productId = currentRow.dataset.productId;
        const locationId = currentRow.dataset.locationId;
        const unitId = currentRow.dataset.unitId;
        const customerId = currentRow.dataset.customerId;

        const theoreticalStockTd = currentRow.querySelector('.theorical_quantity');
        const theoreticalStock = parseFloat(theoreticalStockTd.textContent);
        
        const realStock = parseFloat(event.target.value) || 0; // Si la valeur est vide, on considère que le stock réel est de 0
        const difference = realStock - theoreticalStock;
        
        const stockDifferenceTd = currentRow.querySelector(`#stock-difference-${productId}-${locationId}`);
        stockDifferenceTd.textContent = difference;

        updateTotalDifferenceByUnit(productId, unitId, customerId);
    });
    $(".stock-input-units").trigger("change");

    $(document).off("change", ".slc-stock-on-incident"); 
    $(document).on("change", ".slc-stock-on-incident", function() {
        var stockId = $(this).val();
        var form = $(this).closest(".added_stock");
        $.ajax({  
            url: '/stocks/' + stockId + '/quantities',
            method: 'GET',
            dataType: 'html',
            success: function(data) {
                if(data != undefined){
                    data = JSON.parse(data);
                    var unit_quantity = null;
                    if(data != undefined && data.unit_quantity != undefined){
                        unit_quantity = JSON.parse(data.unit_quantity);
                    }
                    $(form).find(".span_unit_quantity").text(unit_quantity.name);
                    $(form).find("#incident_unit_quantity").val(unit_quantity.id);
                    $(form).find("#incident_quantity").val(data.quantity);
                    $(form).find(".incident_quantities").removeClass("hide");
                }
            }
        });
    });

    $(document).off("click", ".chip"); 
    $(document).on("click", ".chip", function() {
        var $chip = $(this);
        $chip.toggleClass('selected');
        $chip.find(".checkmark").toggleClass('selected');
        
        const tagId = parseInt(this.dataset.tagId);
        var tagIds = JSON.parse($(this).parent().parent().find('#tag-ids').val() || '[]');

        if ($chip.hasClass('selected')) {
            // Ajouter le tag si sélectionné
            tagIds.push(tagId);
        } else {
            // Supprimer le tag si non sélectionné
            var index = tagIds.indexOf(tagId);
            if (index > -1) {
                tagIds.splice(index, 1);
            }
        }

        $(this).parent().parent().find('#tag-ids').val(JSON.stringify(tagIds));
    
        if($(this).parent().parent().parent().parent().hasClass("tag-select-hover")){
            let selected_tags = $(this).parent().find(".chip.selected"); 
            let svg = $(this).parent().parent().parent().parent().find("svg")[0];
            if(typeof svg != "undefined"){
                let gradient = $(svg).find("linearGradient");
                $(gradient).find("stop").remove();
    
                if (selected_tags.length == 0) {
                    $(gradient).append('<stop class="initial_stop" offset="100%" style="stop-color: black; stop-opacity: 1" />');
                } else {
                    $(selected_tags).each(
                        function(index, tag){
                            let color = hexToRgb($(tag).data("color")); // S'assure que $(tag) cible le div du tag et non l'icône
                            let offset = index / (selected_tags.length ? selected_tags.length : 1) * 100;
                            $(gradient).append('<stop data-tag-id="' + tagId + '" offset="' + offset + '%" style="stop-color: ' + color + '; stop-opacity: 1" />');
                        }
                    )
                }
    
                let clonedSvg = $(svg).clone(true);
                let svgParent = $(svg).parent();
                $(svgParent).find("svg").remove();
                svgParent.append(clonedSvg);
                svgParent.html(svgParent.html() + ' ');
            }
        }
    });
    
    // Appeler calculateAndUpdateStorage chaque fois que la valeur de l'input change
    $(document).on('change', '.field_location_size', calculateAndUpdateStorage);

    $(document).off('click', ".remove_part_of_warehouse");
    $(document).on('click', ".remove_part_of_warehouse", function(e) {
        let $parent = $(this).parent().parent();
        if(!$parent.hasClass("level") && !$parent.hasClass("aisle")){
            $parent = $(this).parent().parent().parent();
        }
        let $input_id = $(this).parent().parent().find("input[type='hidden']");
        if(typeof $input_id[0] != "undefined"){
            let html_input = $input_id[0].outerHTML;
            html_input = html_input.replace("[id]", "[_destroy]");
            $parent.append(html_input);
            $parent.find("input[type='hidden']").last().val(true);
        }
        $parent.css("display","none");
        e.preventDefault();
        return false;
    });

    // Appeler une première fois pour initialiser les valeurs
    calculateAndUpdateStorage();
    calculateWarehouseDivSize();

    $('input').each(function() {
        if ($(this).val()) {
          $(this).siblings('label').addClass('active');
        }
    });

    const modals = document.querySelectorAll('.modal');
    M.Modal.init(modals, options);

    // Gestion des tooltips
    const tooltips = document.querySelectorAll('.tooltipped');
    M.Tooltip.init(tooltips);
 
    $(document).on('click', '.add_fields', function() {
        // Réinitialisez les tooltips.
        var elems = document.querySelectorAll('.tooltipped');
        var instances = M.Tooltip.init(elems);
    });

    const notices = document.querySelectorAll('[data-notice]');
    notices.forEach((notice) => {
      const type = notice.dataset.noticeType;
      let classes = 'rounded';
      let icon = '';

      if (type === 'success') {
        classes += ' background-green';
        icon = '<i class="material-icons margin-right-15">check_circle</i>';
      } else if (type === 'warning') {
        classes += ' background-orange';
        icon = '<i class="material-icons margin-right-15">warning</i>';
      } else if (type === 'error') {
        classes += ' background-red';
        icon = '<i class="material-icons margin-right-15">warning</i>';
      }
      
      showToast(icon + " " + notice.dataset.notice, classes)
    });
  
    const notificationMessage = sessionStorage.getItem("notificationMessage");
    const notificationType = sessionStorage.getItem("notificationType");
    if (notificationMessage) {
      let classes = 'rounded';
      let icon = '';
  
      if (notificationType === 'success') {
        classes += ' background-green';
        icon = '<i class="material-icons margin-right-15">check_circle</i>';
      } else if (notificationType === 'warning') {
        classes += ' background-orange';
        icon = '<i class="material-icons margin-right-15">warning</i>';
      } else if (notificationType === 'error') {
        classes += ' background-red';
        icon = '<i class="material-icons margin-right-15">warning</i>';
      }
      showToast(icon + " " + notificationMessage, classes)
      sessionStorage.removeItem("notificationMessage");
      sessionStorage.removeItem("notificationType");
    }

    const openInventoryModalBtn = document.getElementById('open-inventory-modal');

    if (openInventoryModalBtn != null) {
        openInventoryModalBtn.addEventListener('click', function () {
            fetch('/inventories/new_inventory_modal')
            .then((response) => response.text())
            .then((html) => {
                const parser = new DOMParser();
                const htmlDoc = parser.parseFromString(html, 'text/html');
                return htmlDoc.querySelector('body').innerHTML;
            })
            .then((data) => {
                const modalContent = document.querySelector('#inventoryModal .modal-content');
                modalContent.innerHTML = data;
    
                // Initialise les éléments select ici, après avoir mis à jour le contenu de la modal
                const selectElems = modalContent.querySelectorAll('select');
                M.FormSelect.init(selectElems);
    
                // Puis ouvre la modal
                const inventoryModal = M.Modal.getInstance(document.getElementById('inventoryModal'));
                inventoryModal.open();


                updateSubmitButtonState();
    
                // Attacher les gestionnaires d'événements aux champs de la modal
                $('input[name="inventory[title]"]').on('input', updateSubmitButtonState);
                $('select[name="inventory[product_ids][]"]').on('change', updateSubmitButtonState);
                $('select[name="inventory[warehouse_ids][]"]').on('change', updateSubmitButtonState);
            });

        });
    }

    $(document).off('blur', '#inventory_warehouses .select-wrapper');
    $(document).on('blur', '#inventory_warehouses .select-wrapper', function() {
        var selectedWarehouses = $("#inventory_warehouses_select").val();
        // var progressBar = $("#new-inventory-form").find(".progress");
        if(selectedWarehouses.length > 0){
            $.ajax({
                url: "/fetch_aisles_for_inventory",
                method: 'GET',
                dataType: 'json',
                data:{
                    warehouses : selectedWarehouses.join(',')
                },
                success: function(data) {
                    var aislesOptions = data.map(function(aisle) {
                        return '<option value="' + aisle.id + '">' + aisle.name + '</option>';
                    }).join('');
            
                    $('#aisles-select').html(aislesOptions);
                    M.FormSelect.init($('#aisles-select'));
                    $("#aisles-select-container").removeClass("none");
                }
            });
        } else {
            $('#aisles-select').empty();
            $("#aisles-select-container").addClass("none");
        }
    });

    $(document).off("click", '.modal-close');
    $(document).on("click", '.modal-close', function (e) {
        e.preventDefault();
    });

    const clotureInventoryModalBtn = document.getElementById('close_inventory');
    if(clotureInventoryModalBtn){
        clotureInventoryModalBtn.addEventListener('click', function (e) {
            const clotureInventoryModal = M.Modal.getInstance(document.getElementById('closeInventoryModal'));
            clotureInventoryModal.open();
            e.preventDefault();
        });
    }

    const closeInventoryModalBtn = document.getElementById('close-inventory-cloture');
    if(closeInventoryModalBtn){
        closeInventoryModalBtn.addEventListener('click', function (e) {
            const clotureInventoryModal = M.Modal.getInstance(document.getElementById('closeInventoryModal'));
            clotureInventoryModal.close();
            e.preventDefault();
        });
    }

    const validClosureInventoryModalBtn = document.getElementById('btn-valid-with-cloture');
    if(validClosureInventoryModalBtn){
        validClosureInventoryModalBtn.addEventListener('click', function (e) {
            $("form").append("<input type='hidden' value='1' name='close_inventory' />");
        });
    }

    document.querySelectorAll('.sort').forEach(th => {
        let sortOrder = 'asc';
        
        th.addEventListener('click', function() {
            const sortBy = this.getAttribute('data-sort');
            const currentOrderIcon = this.querySelector('.sort-icon');
            
            tinysort(`table tbody tr`, {selector: `td[data-${sortBy}]`, order: sortOrder});
            
            if (sortOrder === 'asc') {
                sortOrder = 'desc';
                currentOrderIcon.textContent = '↓'; // Unicode for downward arrow
            } else {
                sortOrder = 'asc';
                currentOrderIcon.textContent = '↑'; // Unicode for upward arrow
            }
        });
    });

    var elems = document.querySelectorAll('.tabs');
    var instances = M.Tabs.init(elems, options);

    var selectElements = document.querySelectorAll('.selectize');
    selectElements.forEach(function(element, options) {
        $(element).selectize(options);
    });

    $(document).on("change", '.slc_product_on_order', function() {
        var productId = $(this).val();
        var $parent = $(this).parent().parent();
        if (productId !== '') {
          $.getJSON(`/products/${productId}/product_related_items`, function(data) {
            // videz les listes déroulantes    
            $parent.find('.slc_exit_on_order').empty();
            $parent.find('.slc_stock_on_order').empty();
        
            // ajoutez les nouvelles options à la liste déroulante product_exits
            $.each(data.product_exits, function(key, value) {
                $parent.find('.slc_exit_on_order').append(`<option value="${value.id}">${value.title}</option>`);
            });
        
            // ajoutez les nouvelles options à la liste déroulante stocks
            $.each(data.stocks, function(key, value) {
                $parent.find('.slc_stock_on_order').append(`<option value="${value.id}">${value.name}</option>`);
            });
          });
        } else {
          // videz les listes déroulantes
          $parent.find('.slc_exit_on_order').empty();
          $parent.find('.slc_stock_on_order').empty();
        }
      });
      
      $(document).off("click", ".btn_quick_create_warehouse");
      $(document).on("click", ".btn_quick_create_warehouse", function(e) {
          const aisles = $("input[name='ipt_nb_aisles_quick_create']").val();
          const levels = $("input[name='ipt_nb_levels_quick_create']").val();
          const locations = $("input[name='ipt_nb_location_quick_create']").val();
      
          const height = $("input[name='ipt_height_quick_create']").val();
          const length = $("input[name='ipt_length_quick_create']").val();
          const width = $("input[name='ipt_width_quick_create']").val();

          for (let i = 0; i < aisles; i++) {
              $(".btn-add-aisle span").trigger("click");
              for (let j = 0; j < levels; j++) {
                  $(".aisle:visible").last().find(".add-level span").trigger("click");
                  for (let k = 0; k < locations; k++) {
                      $(".level:visible").last().find(".add-location span").trigger("click");
                      let locationDiv = $(".location:visible").last();
                        locationDiv.find("input[name$='[height]']").val(height);
                      locationDiv.find("input[name$='[length]']").val(length);
                      locationDiv.find("input[name$='[width]']").val(width);
                  }
              }
          }

          calculateWarehouseDivSize();
          calculateAndUpdateStorage();
          e.preventDefault();
          return false;
      });

      $("#reset-warehouse").click(function(e) {
        e.preventDefault();
    
        // Trigger the click on the remove link for all aisles, levels, and locations.
        $('.aisle .remove_fields i').trigger('click');
        $('.level .remove_fields i').trigger('click');
        $('.location .remove_fields i').trigger('click');
        
        return false;
    });
        
    $(document).off("change", ".variable-size-checkbox");
    $(document).on("change", ".variable-size-checkbox",
        function(){
            let checkbox = $(this)[0];
            $(".dimensions").each(
                function(i, el){
                    if(checkbox.checked){
                        $(el).addClass("hide");
                    }
                    else{
                        $(el).removeClass("hide");
                    }
                }
            )
        }
    );

    $(document).off("change", ".is-exit-checkbox");
    $(document).on("change", ".is-exit-checkbox",
        function(){
            let checked = $(this)[0].checked;
            if(checked){
                $(".abundance").removeClass("hide");
            }
            else{
                $(".abundance").addClass("hide");
            }
        }
    );

    $(document).off("click", ".status_flow");
    $(document).on("click", ".status_flow",
        function(){
            $(this).toggleClass("validated");
            var validated = $(this).hasClass("validated");
            $(this).next("input").val(validated);

            if(validated){
                var text = $(this).data("text-validated");
            }
            else{
                var text = $(this).data("text-pending");
            }
            $(this).data("tooltip", text);
            $(this).attr("data-tooltip", text);
            M.Tooltip.init(this);
        }
    );

    $(document).off("click", ".remove_entry_item");
    $(document).on("click", ".remove_entry_item",
        function(){
            var div_added_product = $(this).closest(".added_product");
            removeWarehouseFromTabCells(div_added_product);
        }
    )

    $(document).off('cocoon:after-insert').on('cocoon:after-insert', function(e, insertedItem) {
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems);

        let item = $(insertedItem);
        let item_prev = item.prev(""); // col s12 parent
        console.log(item_prev.next())
    
        // Move item to its container
        if ($(item).hasClass('aisle') ||  $(item).hasClass('level') || $(item).hasClass('location')) {
            item.appendTo($(item).next().children(".nested-container"));

            let entityType = '';  // Initialise le type d'entité.

            // Détermine le type d'entité.
            if (item.hasClass('aisle')) {
                entityType = 'aisle';
            } else if (item.hasClass('level')) {
                entityType = 'level';
            } else if (item.hasClass('location')) {
                entityType = 'location';
            }

            // Crée un ID unique basé sur le type d'entité et le timestamp.
            let uniqueId = entityType + '-' + new Date().getTime();

            // Remplace {gradient_id} par l'ID unique dans le svg.
            let svgHtml = item.find('svg').first().prop('outerHTML');
            svgHtml = svgHtml.replaceAll('{gradient_id}', uniqueId);
            item.find('svg').first().replaceWith(svgHtml);
            
            let locationName = 'Emplacement '; // + levelName + locationNumber;
            
            // Apply the names and codes
            if (item.hasClass('aisle')) {
                let aisleNumber = $(".aisle:visible").length;
                let aisleLetter = getLetter(aisleNumber);
                let aisleName = 'Allée ' + aisleLetter;
                let aisleCode = aisleLetter;

                item.find('input[name*="aisles_attributes"][name*="[name]"]').val(aisleName);
                item.find('input[name*="aisles_attributes"][name*="[code]"]').val(aisleCode);
            } else if (item.hasClass('level')) {
                let parent_aisle = $(item).parent().parent().parent().parent();
                let levelNumber = $(parent_aisle).find(".level:visible").length-1;
                let aisleCode = $(parent_aisle).find('input[name*="aisles_attributes"][name*="[code]"]').val();

                let levelName = 'Niveau ' + aisleCode + levelNumber;
                let levelCode = aisleCode + levelNumber;
                item.find('input[name*="levels_attributes"][name*="[name]"]').val(levelName);
                item.find('input[name*="levels_attributes"][name*="[code]"]').val(levelCode);
            } else if (item.hasClass('location')) {
                let parent_level = $(item).parent().parent().parent().parent();
                let levelLocation = $(parent_level).find(".location:visible").length;
                let levelCode = $(parent_level).find('input[name*="levels_attributes"][name*="[code]"]').val();
                let locationNumber = item.find(".location").length;

                let locationName = 'Emplacement ' + levelCode + levelLocation;
                let locationCode = levelCode + levelLocation;
                item.find('input[name*="locations_attributes"][name*="[name]"]').val(locationName);
                item.find('input[name*="locations_attributes"][name*="[code]"]').val(locationCode);
            }
        }

        item_prev = $(insertedItem).closest(".added_stock");
        if($(item_prev).find(".slc-stock-on-incident").length > 0){
            $(item_prev).find(".slc-stock-on-incident").select2({
                ajax: {
                    url: function() {
                        return $(this).data('url');
                    },
                    dataType: 'json',
                    delay: 250,
                    data: function (params) {
                        return {
                            q: params.term // termes de recherche entrés par l'utilisateur
                        };
                    },
                    processResults: function (data, params) {
                        return {
                            results: data
                        };
                    },
                    cache: true
                },
                placeholder: 'Recherchez un stock'
            });
        }
    
        calculateWarehouseDivSize();
    });
        
    $(document).on("click", "#submit_entry", function(e) {
        $(".added_product").each(
            function(selectedProductIndex, product_form){
                // Mettez à jour le nom des champs pour les lier au produit sélectionné
                $(product_form).find("input[name='selected_locations_quantities[]']").each(function(index, element) {
                    $(element).attr("name", "product_entry[product_entry_items_attributes][" + selectedProductIndex + "][selected_locations_quantities][]");
                });
            
                $(product_form).find("input[name='selected_locations_unit_quantity_ids[]']").each(function(index, element) {
                    $(element).attr("name", "product_entry[product_entry_items_attributes][" + selectedProductIndex + "][selected_locations_unit_quantity_ids][]");
                });
            
                $(product_form).find("input[name='selected_locations[]']").each(function(index, element) {
                    $(element).attr("name", "product_entry[product_entry_items_attributes][" + selectedProductIndex + "][selected_locations][]");
                });
            }
        );
    });

    $(document).on("click", "#submit_exit", function(e) {
        $(".added_product").each(function(selectedProductIndex, product_form){
            // Mettez à jour l'attribut data avec l'index
            $(product_form).closest('.nested-fields').attr('data-index-id', selectedProductIndex);
            
            // Mettez à jour le champ caché avec cet index
            //var stockHashId = $(product_form).find("[name*='stock_product_exit_item_indices']").attr('name').match(/\[(\d+)\]/)[1];
            $(product_form).find("[name*='selected_stocks").each(
                function(){
                    if($(this)[0].checked == true){
                        $(this).parent().find("[name*='stock_product_exit_item_indices").val(selectedProductIndex);
                    }
                }
            )
        });
    });

    $(document).off('click', ".logo-option");
    $(document).on('click', ".logo-option", function() {
        // Désélectionner tous les logos précédemment sélectionnés
        $('.logo-option input').prop('checked', false);
        $('.logo-option img').removeClass('selected');
    
        // Sélectionner le logo cliqué
        $(this).find('input').prop('checked', true);
        $(this).find("img").addClass("selected");
    
        // Mettre à jour la valeur du champ caché
        $('input[name="unit_quantity[icon_path]"]').val($(this).data('path'));
    });

    $(".slc-exit-on-incident").select2({ 
        ajax: {
            url: function() {
            return $(this).data('url');
            },
            dataType: 'json',
            delay: 250,
            data: function (params) {
            return {
                q: params.term // termes de recherche entrés par l'utilisateur
            };
            },
            processResults: function (data, params) {

            return {
                results: data
            };
        },
            cache: true
        },
        placeholder: 'Recherchez une sortie'
    });

    $(".slc-entry-on-incident").select2({
        ajax: {
            url: function() {
            return $(this).data('url');
            },
            dataType: 'json',
            delay: 250,
            data: function (params) {
            return {
                q: params.term // termes de recherche entrés par l'utilisateur
            };
            },
            processResults: function (data, params) {

            return {
                results: data
            };
        },
            cache: true
        },
        placeholder: 'Recherchez une sortie'
    });

    $(".slc-transfer-on-incident").select2({
        ajax: {
            url: function() {
            return $(this).data('url');
            },
            dataType: 'json',
            delay: 250,
            data: function (params) {
            return {
                q: params.term // termes de recherche entrés par l'utilisateur
            };
            },
            processResults: function (data, params) {

            return {
                results: data
            };
        },
            cache: true
        },
        placeholder: 'Recherchez une sortie'
    });

    $(".slc-stock-on-incident").select2({
        ajax: {
            url: function() {
                return $(this).data('url');
            },
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term // termes de recherche entrés par l'utilisateur
                };
            },
            processResults: function (data, params) {
                return {
                    results: data
                };
            },
            cache: true
        },
        placeholder: 'Recherchez un stock'
    });

    resize_warehouse();

    if($("#editor:visible").length > 0){
        const editor = createJodit('#editor', 'editor');

        editor.events.on('change', function () {
              const tables = editor.editor.querySelectorAll('table');
        
              tables.forEach((table) => {
                // Si un élément <thead> n'est pas présent, créez-en un
                if (!table.querySelector('thead')) {
                  const thead = document.createElement('thead');
                  const firstRow = table.querySelector('tr');
        
                  if (firstRow) {
                    firstRow.parentNode.removeChild(firstRow);
                    thead.appendChild(firstRow);
                    table.insertBefore(thead, table.firstChild);
                  }
                }
              });
            
        });
    }

    if($("#searchInputCustomDoc").length > 0){
        const searchInput = document.getElementById('searchInputCustomDoc');
        searchInput.addEventListener("keyup", function() {
            const filter = searchInput.value.toUpperCase();
            const table = document.querySelector("table");
            const trs = table.getElementsByTagName("tr");

            for (let i = 1; i < trs.length; i++) {
                let tds = trs[i].getElementsByTagName("td");
                let found = false;

                for (let j = 0; j < tds.length; j++) {
                    if (tds[j]) {
                    let textValue = tds[j].textContent || tds[j].innerText;
                    if (textValue.toUpperCase().indexOf(filter) > -1) {
                        found = true;
                        break;
                    }
                    }
                }
                
                if (found) {
                    trs[i].style.display = "";
                } else {
                    trs[i].style.display = "none";
                }
            }
        });
    }

    function comparer(index, asc) {
        return function(a, b) {
            const valA = getCellValue(a, index);
            const valB = getCellValue(b, index);
            // Compare as numbers if possible
            if (!isNaN(valA) && !isNaN(valB)) {
                return (valA - valB) * (asc ? 1 : -1);
            }
            // Otherwise compare as strings
            return valA.localeCompare(valB) * (asc ? 1 : -1);
        };
    }
    
    function getCellValue(tr, idx) {
        return tr.children[idx] ? tr.children[idx].innerText || tr.children[idx].textContent : "";
    }

    document.querySelectorAll('th:not(.not_sortable)').forEach(th => th.addEventListener('click', (() => {
        const table = th.closest('table');
        const tbody = table.querySelector('tbody');
        const rows = Array.from(tbody.querySelectorAll('tr.main-row'));
        
        // Obtenir l'index de la colonne sur laquelle le tri sera effectué
        const columnIndex = Array.from(th.parentNode.children).indexOf(th);
        const asc = this.asc = !this.asc;

        th.parentNode.querySelectorAll('th').forEach(otherTh => {
            otherTh.classList.remove('sort-asc', 'sort-desc');
        });
        th.classList.add(asc ? 'sort-asc' : 'sort-desc');
        
        rows.sort(comparer(columnIndex, asc)).forEach(mainRow => {
            tbody.appendChild(mainRow);
            
            // Find and move the associated detail row, if it exists
            let detailRowId = mainRow.getAttribute('data-target');
            if (detailRowId) {
                let detailRow = tbody.querySelector(`tr.details-row[data-detail-of="${detailRowId}"]`);
                if (detailRow) {
                    tbody.appendChild(detailRow);
                }
            }
        });
    })));

    $(document).off("click", '.button-loader');
    $(document).on("click", '.button-loader', function(event) {
        event.stopPropagation();
        var $this = $(this);
        $this.empty();
        $this.append('<div class="preloader-wrapper extra-small active"><div class="spinner-layer spinner-white"><div class="circle-clipper left"><div class="circle"></div></div><div class="gap-patch"><div class="circle"></div></div><div class="circle-clipper right"><div class="circle"></div></div></div></div>');
        
        $this.removeClass("waves-effect waves-light submit").addClass('disabled');
    });

    var invoice_adress_toggle = document.querySelector(".invoice-address-toggle");
    var invoice_adress_fields = document.querySelector(".invoice-address-fields");
  
    if(invoice_adress_toggle != null){
        invoice_adress_toggle.addEventListener("change", function() {
          invoice_adress_fields.style.display = this.checked ? "block" : "none";
        });
  
        if (invoice_adress_toggle.checked) {
          invoice_adress_fields.style.display = "block";
        }
    }

    $(document).off("click", "#selected_all_movements");
    $(document).on("click", "#selected_all_movements", function(all, j){
        $("input[name='selected_movements[]']").removeAttr("checked");
        $("input[name='selected_movements[]']").each(
            function(i, el){ 
                $(el)[0].checked = false; 
                if($(el).closest("table").find("#selected_all_movements")[0] != $(all.currentTarget)[0]){
                    $(el).closest("table").find("#selected_all_movements")[0].checked=false;
                } 
            }
        );
        $("input[name='selected_movements[]']").trigger("change");

        if($(this)[0].checked){
            $(this).closest("table").find("input[name='selected_movements[]']").attr("checked", "true");
            $(this).closest("table").find("input[name='selected_movements[]']").each(function(i, el){ $(el)[0].checked = true;});
            $(this).closest("table").find("input[name='selected_movements[]']").trigger("change");
        }
        else{
            $(this).closest("table").find("input[name='selected_movements[]']").removeAttr("checked");
            $(this).closest("table").find("input[name='selected_movements[]']").each(function(i, el){ $(el)[0].checked = false;});
            $(this).closest("table").find("input[name='selected_movements[]']").trigger("change");
        }
    });

    $(document).off("change", "input[name='selected_movements[]']");
    $(document).on("change", "input[name='selected_movements[]']",
        function(){
            var customer_id = $(this).data("customer-id");
            if(!$(this)[0].checked)
                $(this).removeAttr("checked");

            if($(this)[0].checked || $(this).attr("checked")){
                if($("input[name=customer_id]").val() == ""){
                    $("input[name=customer_id]").val(customer_id);
                }
                else{
                    if(customer_id != $("input[name=customer_id]").val()){
                        alert("Vous ne pouvez facturer plusieurs clients à la fois");
                        $(this).removeAttr("checked");
                        $(this)[0].checked = false;
                    }
                }
            }
            else{
                if($("input[name='selected_movements[]'][data-customer-id="+customer_id+"][checked]").length == 0){
                    $("input[name=customer_id]").val("");
                }
            }
        }
    );

    var spe_billing_toggle = document.querySelector("#customer_use_spe_billing_footer");
    var spe_billing_fields = document.querySelector("#spe_billing_footer_textarea");
  
    if(spe_billing_toggle != null){
        spe_billing_toggle.addEventListener("change", function() {
          spe_billing_fields.style.display = this.checked ? "block" : "none";
        });
  
        if (spe_billing_toggle.checked) {
          spe_billing_fields.style.display = "block";
        }
    }

    let rotated = false;
    $(document).off("click", '.filter-trigger');
    $(document).on("click", '.filter-trigger', function() {
        const icon = $(this).find('.filter-icon');
        rotated = !rotated;
        icon.css("transform", rotated ? "rotate(180deg)" : "rotate(0deg)");
        $('.filter').toggleClass('display_filter');
        initTableHeight();
    });

    const filterTagsCheckboxes = document.querySelectorAll('.tag_checkbox');
    const filterFormElements = document.querySelectorAll('.filter input, .filter select, .filter textarea');

    const filterCounter = () => {
        let count = Array.from(filterTagsCheckboxes).filter(checkbox => checkbox.checked).length;
        // Compter les filtres supplémentaires (input, select, etc.) s'ils sont remplis
        filterFormElements.forEach(element => {
            if (element.type !== 'checkbox' && element.value.trim() !== '') {
                count++;
            }
        });
        $(".filter-text span.filter-count").remove();
        $(".filter-text").append(count ? `<span class="filter-count">${count}</span>` : "");
    };

    filterCounter();

    $(document).off("click", '.filter-clear'); 
    $(document).on("click", '.filter-clear', function(e) {
        e.preventDefault();
        e.stopPropagation();
        filterTagsCheckboxes.forEach(checkbox => {
            checkbox.checked = false;
        });
        filterFormElements.forEach(element => {
            if (element.type !== 'checkbox') {
                element.value = '';
            }
        });
        document.querySelector('.filter_form').submit();
    });

     // Dans ton script JavaScript de Stoka
    $(document).off("click", '.btn_preview, .btn_bill'); 
    $(document).on("click", '.btn_preview, .btn_bill', function(e) {
        e.preventDefault();
        var data = new FormData($(".form_invoice")[0]);
        data.append('is_credit', $("input[name=is_credit]").val());
        if($("input[name=facti_id]").val() > 0){
            data.append('facti_id', $("input[name=facti_id]").val());
        }
        if($("input[name=invoice_reference_id]").val() > 0){
            data.append('invoice_reference_id', $("input[name=invoice_reference_id]").val());
        }
        data.append('is_replaced', $("input[name=is_replaced]").val()); 

        if($(this).hasClass("btn_bill")){
            data.append('valid_invoice', true);
        }

        data.delete("authenticity_token");

        $.ajax({
            url: '/invoices/create',
            type: 'POST',
            data: data,
            headers: {
                "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content
            },
            processData: false,  // Ne pas traiter les données
            contentType: false, 
            success: function(data) {
                // Vérifier si le PDF est retourné
                if(data.facti_id){
                    $("input[name=facti_id]").val(data.facti_id);
                }
                if(data.service_ids){
                    $(data.service_ids).each(
                        function(index, id){
                            if($($(".service_id")[index]).length > 0){
                                $($(".service_id")[index]).val(id);
                                $($(".service_id")[index]).attr("value", id);
                            }
                            else{
                                var html = "<input type='hidden' class='service_id' name='invoiced_services["+index+"][id]' value='"+id+"' />"
                                $(".hidden_isp_fields_" + index).append(html)
                            }
                        }
                    )
                }
                if (data.pdf) {
                    var pdfData = atob(data.pdf);
                    // Convertit les données binaires en un tableau d'octets
                    var byteNumbers = new Array(pdfData.length); 
                    for (var i = 0; i < pdfData.length; i++) {
                        byteNumbers[i] = pdfData.charCodeAt(i);
                    }
                    var byteArray = new Uint8Array(byteNumbers);

                    // Crée un blob à partir du tableau d'octets
                    var blob = new Blob([byteArray], {type: 'application/pdf'});
                    var url = URL.createObjectURL(blob);
                    window.open(url, '_blank');
                } else {
                    // Affiche une erreur si le PDF n'est pas retourné
                    console.error('Le PDF n\'est pas retourné');
                }
            },
            error: function(xhr, status, error) {
                // Gérer les erreurs ici
                console.error('Erreur AJAX:', status, error); 
            }
        });
        
    }); 


     $(document).off("click", '.btn_get_pdf'); 
     $(document).on("click", '.btn_get_pdf', function(e) {
        e.preventDefault();
 
         $.ajax({
             url: '/invoices/get_invoice_from_facti',
             type: 'POST',
             data: JSON.stringify({ id: $(this).attr("data-id") }),
            headers: {
                "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
                "Content-Type": "application/json"
            },
             processData: false,  // Ne pas traiter les données
             contentType: false, 
             success: function(data) {
                 if (data.pdf) {
                     var pdfData = atob(data.pdf);
                     // Convertit les données binaires en un tableau d'octets
                     var byteNumbers = new Array(pdfData.length); 
                     for (var i = 0; i < pdfData.length; i++) {
                         byteNumbers[i] = pdfData.charCodeAt(i);
                     }
                     var byteArray = new Uint8Array(byteNumbers);
 
                     // Crée un blob à partir du tableau d'octets
                     var blob = new Blob([byteArray], {type: 'application/pdf'});
                     var url = URL.createObjectURL(blob);
                     window.open(url, '_blank');
                 } else {
                     // Affiche une erreur si le PDF n'est pas retourné
                     console.error('Le PDF n\'est pas retourné');
                 }
             },
             error: function(xhr, status, error) {
                 // Gérer les erreurs ici
                 console.error('Erreur AJAX:', status, error);   
             }
         });
         
     }); 

     $('.show_invoice_association').on('click', function(){
        const id = $(this).attr("data-id");
        const currentTransform = $(this).css("transform");
        if(currentTransform === "matrix(-1, 0, 0, -1, 0, 0)"){
          $(this).css("transform", "rotate(0deg)");
          // $(this).closest('tr').css('border-bottom', '1px solid rgba(0,0,0,0.12)')
        } else {
          $(this).css("transform", "rotate(180deg)");
          // $(this).closest('tr').css('border-bottom', 'none')
        }
        $(`.invoice_association[data-id='${id}']`).toggle();
        initTableHeight();
        hideBorderPrevLine();
      })

    $(document).off("click", '#add_service'); 
    $(document).on("click", '#add_service', function(e) {
        e.preventDefault();
        var container = $("#invoiced_services");
        var template = document.getElementById('service-template').innerHTML;
        var newIndex = $('.nested-fields').length; // Obtient le nombre actuel de champs
        var newDiv = template.replace(/index_placeholder/g, newIndex);
        
        // Vérifie si au moins un élément .nested-fields existe
        if ($(".nested-fields").length > 0) {
            $(".nested-fields").last().after(newDiv);
        } else {
            // S'il n'y a pas d'élément .nested-fields, ajoute le nouveau div directement dans le conteneur
            container.append(newDiv);
        }
        
        // Initialisation des éléments select pour Materialize
        var elems = document.querySelectorAll('select');
        var instances = M.FormSelect.init(elems);
    });

    $(document).off("click", '.remove_service'); 
    $(document).on("click", '.remove_service', function(e) {
        e.preventDefault();
        if(confirm("Êtes-vous sûr de vouloir supprimer cette prestation ? "))
            $(this).closest(".nested-fields").remove();
    });

    var dropdown_options = {
        constrainWidth: false,
        hover: true,
        alignment: 'right'
      }
      var dropdownElems  = document.querySelectorAll(".dropdown-trigger");
      var dropdownInstances  = M.Dropdown.init(dropdownElems , dropdown_options);
    
    function initTableHeight() {
        const tableau = $(".table_box");
        const espaceEnHaut = tableau.offset().top;
        const hauteurEcran = $(window).height();
        const hauteurMax = hauteurEcran - espaceEnHaut - 70;
    
        tableau.css('max-height', hauteurMax + 'px');
        tableau.css('overflow-y', 'auto');
    }

     // Initialiser la hauteur des tableaux au chargement de la page
    if($(".table_box:visible").length > 0)
        initTableHeight();

    //Event des imports
    const fileUpload = document.getElementById('file-upload');
    const previewTable = document.getElementById('preview-table');
    const importBtn = document.getElementById('import-btn');
    const filePreview = document.getElementById('file-preview');

    if(fileUpload != undefined){
        fileUpload.addEventListener('change', function(event) {
          const file = event.target.files[0];
          if (file && file.type === 'text/csv') {
            const reader = new FileReader();
            reader.onload = function(e) {
              const content = e.target.result;
              const lines = content.split('\n').slice(0, 3);
              const delimiter = content.indexOf(',') !== -1 ? ',' : ';';
              let tableHtml = '<table class="highlight responsive-table"><thead><tr>';
              let firstLineCells = lines[0].split(delimiter);
    
              firstLineCells.forEach(cell => {
                tableHtml += `<th>${cell.trim()}</th>`;
              });
              tableHtml += '</tr></thead><tbody>';
    
              lines.slice(1).forEach(line => {
                let rowCells = line.split(delimiter);
                tableHtml += '<tr>';
                rowCells.forEach(cell => {
                  tableHtml += `<td>${cell.trim()}</td>`;
                });
                tableHtml += '</tr>';
              });
    
              tableHtml += '</tbody></table>';
              previewTable.innerHTML = tableHtml;
              filePreview.style.display = 'block';
              importBtn.disabled = false;
            };
            reader.readAsText(file);
          }
        });
    }

    const tableBox = document.querySelector('.table_box');
    let scroll_loading = false;

    if (tableBox) {
        const loadMoreData = function() {
            // Vérifier s'il y a une page suivante
            let nextPageLink = document.querySelector('.pagination a[rel="next"]');
            if (nextPageLink && !scroll_loading) {
                let url = nextPageLink.getAttribute('href');
                let scrollPosition = tableBox.scrollTop + tableBox.clientHeight;
                let bottom = tableBox.scrollHeight - 50;

                if (scrollPosition > bottom) {
                    scroll_loading = true;
                    document.querySelector('.pagination').innerHTML = 'Chargement...';
                    Rails.ajax({
                        url: url,
                        type: "GET",
                        dataType: "script",
                        success: function() {
                        scroll_loading = false;
                        }
                    });
                }
            } else if (!nextPageLink) {
                tableBox.removeEventListener('scroll', loadMoreData);
            }
        };

        tableBox.addEventListener('scroll', loadMoreData);
    }

}

// Appelez initDropdowns() à chaque fois que le contenu de la page est chargé ou mis à jour
document.addEventListener('turbolinks:load', initSidebarAndDropdowns);
